import { components } from 'outlinejs';
import { AeModal } from '@photosi/albumepoca-ui';
import React from 'react';

export class ShowMoreDialog extends components.BaseComponent {
  render() {
    const {
      dialogTitle,
      dynamicPart,
      modalClasses,
      separator,
      showDialog,
      dynamicText,
      closeCallback
    } = this.props;
    return (
      <AeModal
        isOpen={showDialog}
        title={dialogTitle}
        dynamicText={dynamicText}
        dynamicTextContent={dynamicPart}
        customClasses={modalClasses}
        separator={separator}
        onClose={closeCallback}
      />
    );
  }
}
