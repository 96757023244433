import React from 'react';

import { AeLoader } from '@photosi/albumepoca-ui';
import { conf } from 'outlinejs';
import { runtime } from '@outlinejs/contexts';

import { LayoutView } from '../core/views';

import FrameImg from './media/images/quadri_cornici.jpg';
import AlbumImg from './media/images/album.jpg';
import EventBookImg from './media/images/event_book.jpg';
import MattedPrintsImg from './media/images/matted_prints.jpg';
import UsbBoxImg from './media/images/usb_box.jpg';

import './styles/main.scss';

export class ConfiguratorsListView extends LayoutView {
  renderFrameBtn(btnsList) {
    let items = [];
    for (let key in btnsList) {
      items.push(
        <a
          className="btn cta-primary-inverse display-block"
          key={key}
          href={`${btnsList[key]}&language=${this.request.language}`}>
          {key}
        </a>
      );
    }
    return items;
  }

  render() {
    let frameBtn = conf.settings.IS_PRODUCTION_ENV ? (
      <a
        className="btn cta-primary-inverse"
        href={`https://www.albumepoca.com/${this.request.language}/${
          conf.settings.FRAME_CATALOG[this.request.language]
        }`}>
        {this.i18n.gettext('Scegli questo prodotto')}
      </a>
    ) : (
      <div>{this.renderFrameBtn(conf.settings.DEVELOP_FRAME_URLS)}</div>
    );

    let frameBlock = (
      <div className="col-sm-12 col-md-6 col-lg-6 columns" style={{ float: 'left' }}>
        <div className="product_item">
          <div className="gallery__item__thumb">
            <img src={FrameImg} alt="Frame" />
          </div>
          <h3 className="add_product_title">{this.i18n.gettext('Frames')}</h3>
          <p>
            {this.i18n.gettext(
              'Every photo session deserves to be displayed on a wall or tabletop frame that can fit with any decor.'
            )}
          </p>
          <div className="text-center cta-button">{frameBtn}</div>
        </div>
      </div>
    );

    let catalogueAlbumUrl = null;
    if (runtime.isClient) {
      catalogueAlbumUrl = `${window.location.origin}/album/new?language=${this.request.language}`;
    }

    return (
      <div className="container">
        <AeLoader active={this.props.initViewRendering} />
        <div className="row configurators-list">
          <div className="col-sm-12 col-lg-9 large-centered columns">
            <div className="row">
              <div className="col-sm-12 col-md-6 col-lg-6 columns" style={{ float: 'left' }}>
                <div className="product_item">
                  <div className="gallery__item__thumb">
                    <img src={AlbumImg} alt="Album" />
                  </div>
                  <h3 className="add_product_title">Album</h3>
                  <p>
                    {this.i18n.gettext(
                      'I nostri Album fotografici professionali sono caratterizzati da un design moderno e contemporaneo. La gamma è molto vasta grazie ai numerosi colori, tessuti e formati offerti.'
                    )}
                  </p>
                  <div className="text-center cta-button">
                    <a className="btn cta-primary-inverse" href={catalogueAlbumUrl}>
                      {this.i18n.gettext('Scegli questo prodotto')}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 columns" style={{ float: 'left' }}>
                <div className="product_item">
                  <div className="gallery__item__thumb">
                    <img src={EventBookImg} alt="Event Book" />
                  </div>
                  <h3 className="add_product_title">Event Book</h3>
                  <p>
                    {this.i18n.gettext(
                      'Event Book è un fotolibro professionale moderno e poliedrico, adatto a tutti i tipi di servizi fotografici. Può raccontare cerimonie, matrimoni giovani e moderni, ritratti di famiglia, servizi di still life, moda, reportage, sport, arte.'
                    )}
                  </p>
                  <div className="text-center cta-button">
                    <a
                      className="btn cta-primary-inverse"
                      href={`/eventbook/new/MATERIAL/?language=${this.request.language}`}>
                      {this.i18n.gettext('Scegli questo prodotto')}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 columns" style={{ float: 'left' }}>
                <div className="product_item">
                  <div className="gallery__item__thumb">
                    <img src={MattedPrintsImg} alt="Matted Prints" />
                  </div>
                  <h3 className="add_product_title">Matted Prints</h3>
                  <p>
                    {this.i18n.gettext(
                      'I Matted Prints di Album Epoca rappresentano un’alternativa di design, originale ed elegante, per proporre le tue fotografie con passepartout. Sono la scelta perfetta per comunioni, cresime e battesimi, così come ritratti, shooting di moda, still life, reportage, sport, arte.'
                    )}
                  </p>
                  <div className="text-center cta-button">
                    <a
                      className="btn cta-primary-inverse"
                      href={`/matted-prints/new?language=${this.request.language}`}>
                      {this.i18n.gettext('Scegli questo prodotto')}
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-sm-12 col-md-6 col-lg-6 columns" style={{ float: 'left' }}>
                <div className="product_item">
                  <div className="gallery__item__thumb">
                    <img src={UsbBoxImg} alt="Usb&Box" />
                  </div>
                  <h3 className="add_product_title">Usb&Box</h3>
                  <p>
                    {this.i18n.gettext(
                      'La USB & Box è la miglior soluzione per conservare la memoria di un evento importante. La Box può essere personalizzata con raffinate grafiche studiate per valorizzare il ricordo custodito al suo interno.'
                    )}
                  </p>
                  <div className="text-center cta-button">
                    <a
                      className="btn cta-primary-inverse"
                      href={`/usb-box/new?language=${this.request.language}`}>
                      {this.i18n.gettext('Scegli questo prodotto')}
                    </a>
                  </div>
                </div>
              </div>
              {frameBlock}
            </div>
          </div>
        </div>
      </div>
    );
  }
}
