import React from 'react';
import { LayoutView } from '../core/views';
import { AeModal, AeLoader } from '@photosi/albumepoca-ui';
import { conf } from 'outlinejs';

import AlbumImg from '../configurators-list/media/images/album.jpg';
import EventBookMaterialImg from '../configurators-list/media/images/event_book_material.jpg';
import EventBookPhotographicImg from '../configurators-list/media/images/event_book_photographic.jpg';

export class DuplicateProductsView extends LayoutView {
  constructor(props) {
    super(props);
  }

  renderAlbum(productType, duplicateCode) {
    return (
      <div className="col-sm-12 col-md-6 col-lg-6 columns" style={{ float: 'left' }}>
        <div className="product_item">
          <div className="gallery__item__thumb">
            <img src={AlbumImg} alt="Album" />
          </div>
          <h3 className="add_product_title">Album</h3>
          <p>
            {this.i18n.gettext(
              'I nostri Album fotografici professionali sono caratterizzati da un design moderno e contemporaneo. La gamma è molto vasta grazie ai numerosi colori, tessuti e formati offerti.'
            )}
          </p>
          <div className="text-center cta-button">
            <a
              className="btn cta-primary-inverse"
              href={`/duplicate-project?projectId=${this.props.projectId}&destinationProductType=${productType}&duplicateCode=${duplicateCode}&language=${this.request.language}`}>
              {this.i18n.gettext('Scegli questo prodotto')}
            </a>
          </div>
        </div>
      </div>
    );
  }

  renderEventBookMaterial(productType, duplicateCode) {
    return (
      <div className="col-sm-12 col-md-6 col-lg-6 columns" style={{ float: 'left' }}>
        <div className="product_item">
          <div className="gallery__item__thumb">
            <img src={EventBookMaterialImg} alt="Event Book Material" />
          </div>
          <h3 className="add_product_title">{this.i18n.gettext('Event Book Material')}</h3>
          <p>
            {this.i18n.gettext(
              'Event Book è un fotolibro professionale moderno e poliedrico, adatto a tutti i tipi di servizi fotografici. Può raccontare cerimonie, matrimoni giovani e moderni, ritratti di famiglia, servizi di still life, moda, reportage, sport, arte.'
            )}
          </p>
          <div className="text-center cta-button">
            <a
              className="btn cta-primary-inverse"
              href={`/duplicate-project?projectId=${this.props.projectId}&destinationProductType=${productType}&duplicateCode=${duplicateCode}&language=${this.request.language}`}>
              {this.i18n.gettext('Scegli questo prodotto')}
            </a>
          </div>
        </div>
      </div>
    );
  }

  renderEventBookPhotographic(productType, duplicateCode) {
    return (
      <div className="col-sm-12 col-md-6 col-lg-6 columns" style={{ float: 'left' }}>
        <div className="product_item">
          <div className="gallery__item__thumb">
            <img src={EventBookPhotographicImg} alt="Event Book Photographic" />
          </div>
          <h3 className="add_product_title">{this.i18n.gettext('Event Book Photographic')}</h3>
          <p>
            {this.i18n.gettext(
              'Event Book è un fotolibro professionale moderno e poliedrico, adatto a tutti i tipi di servizi fotografici. Può raccontare cerimonie, matrimoni giovani e moderni, ritratti di famiglia, servizi di still life, moda, reportage, sport, arte.'
            )}
          </p>
          <div className="text-center cta-button">
            <a
              className="btn cta-primary-inverse"
              href={`/duplicate-project?projectId=${this.props.projectId}&destinationProductType=${productType}&duplicateCode=${duplicateCode}&language=${this.request.language}`}>
              {this.i18n.gettext('Scegli questo prodotto')}
            </a>
          </div>
        </div>
      </div>
    );
  }

  renderCompatibleProducts() {
    if (this.props.compatibleProducts) {
      let albumBox = '';
      let eventBookMaterialBox = '';
      let eventBookPhotographicBox = '';
      this.props.compatibleProducts.forEach((product) => {
        if (product.code === conf.settings.DUPLICATE_ALBUM_DATA.code) {
          albumBox = this.renderAlbum(product.productType, product.duplicateCode);
        }
        if (product.code === conf.settings.DUPLICATE_EVENTBOOK_MATERIAL_DATA.code) {
          eventBookMaterialBox = this.renderEventBookMaterial(
            product.productType,
            product.duplicateCode
          );
        }
        if (product.code === conf.settings.DUPLICATE_EVENTBOOK_PHOTOGRAPHIC_DATA.code) {
          eventBookPhotographicBox = this.renderEventBookPhotographic(
            product.productType,
            product.duplicateCode
          );
        }
      });
      return (
        <div>
          {albumBox}
          {eventBookMaterialBox}
          {eventBookPhotographicBox}
        </div>
      );
    }
  }

  render() {
    return (
      <div className="container">
        <AeLoader active={this.props.initViewRendering} />
        <div className="row configurators-list">
          <div className="col-sm-12 col-lg-9 large-centered columns">
            <div className="row">{this.renderCompatibleProducts()}</div>
          </div>
        </div>
      </div>
    );
  }
}

export class DuplicateView extends LayoutView {
  constructor(props) {
    super(props);

    this.state = {
      showModal: false,
      textModal: '',
      cancelLabelModal: null,
      confirmLabelModal: null,
      titleModal: null,
      confirmCallbackModal: null,
      cancelCallbackModal: null
    };
  }

  render() {
    const {
      showModal,
      textModal,
      cancelLabelModal,
      confirmLabelModal,
      titleModal,
      confirmCallbackModal,
      cancelCallbackModal
    } = this.state;

    return (
      <div>
        <AeLoader active={true} />
        <h2 className="text-center duplicate-loader">
          {this.i18n.gettext('Duplicazione in corso, si prega di attendere')}
        </h2>
        <AeModal
          isOpen={showModal}
          text={textModal}
          title={titleModal}
          confirmLabel={confirmLabelModal}
          dismissLabel={cancelLabelModal}
          onConfirm={confirmCallbackModal}
          onDismiss={cancelCallbackModal}
        />
      </div>
    );
  }
}
