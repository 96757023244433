import { BaseModel, mergeBackboneOptions } from '../core/models';
import { conf } from 'outlinejs';

export class Project extends BaseModel {
  getAbsoluteUrl() {
    return this.urlRoot + this.id + '/';
  }

  get urlRoot() {
    return conf.settings.PROFESSIONAL_PROJECTS_URL;
  }

  get id() {
    return this.get('id');
  }

  get state() {
    return this.get('state');
  }

  set state(value) {
    this.set('state', value);
  }

  get name() {
    return this.get('name');
  }

  set name(value) {
    this.set('name', value);
  }

  get creationStatus() {
    return this.get('creationStatus');
  }

  isDuplicationInProgress() {
    return this.creationStatus.toUpperCase() === 'INPROGRESS';
  }

  fetch(options = {}) {
    let params = {
      data: { softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE, includeValidation: false }
    };
    options = mergeBackboneOptions(options, params);
    return super.fetch(options);
  }
}
