import { runtime } from '@outlinejs/contexts';

import Logger from '../core/logger';

import OAuth2 from './oauth';
import { User } from './models';
import { performLogout } from './utils';

export default class {
  /*
   * Returns customer user and add it to local storage
   * */
  initCustomerUser(user) {
    let customerUser = {};
    let anonymousUser = new User({
      id: null,
      name: 'Anonymous',
      email: null,
      shopCode: null,
      billingAddressId: null
    });

    let jsonCustomerUser = JSON.stringify(anonymousUser);

    // customer user equal to user (if logged in)
    if (user) {
      jsonCustomerUser = JSON.stringify(user);
    }

    localStorage.setItem('__customerUser', jsonCustomerUser);

    try {
      customerUser = JSON.parse(jsonCustomerUser);
    } catch (err) {
      Logger.error('processRequest:parse user from request', {
        error: err,
        jsonCustomerUser: jsonCustomerUser
      });
    }

    return customerUser;
  }

  async setUserByToken(token) {
    if (token) {
      let user = null;
      try {
        user = new User({ id: 'current' });
        await user.fetch({ data: { 'include-membership-info': true } });
        user.token = token;
        let jsonUser = JSON.stringify(user);
        localStorage.setItem('__user', jsonUser);
        OAuth2.saveUserInfoToken({
          id: user.id,
          email: user.email,
          name: user.name,
          shopCode: user.shopCode
        });
        return JSON.parse(jsonUser);
      } catch (err) {
        localStorage.removeItem('__user');
        localStorage.removeItem('__customerUser');
        OAuth2.cleanToken();
        return null;
      }
    }
    return null;
  }

  initRequestUser(request, user) {
    request.user = user;

    // Set customer user
    request.customerUser = this.initCustomerUser(request.user);

    // Set customer user info to Raven
    Logger.addUserContext(request.customerUser);

    // Set user validation
    request.customerUserIsAuthorized = !!(request.customerUser && request.customerUser.shopCode);
  }

  async processRequest(request, response) {
    if (runtime.isClient) {
      let newUrl = request.absoluteUrl.replace(/&token=[^&]+/, '').replace(/\?token=[^&]+/, '');

      if (request.query.token) {
        let token = { accessToken: request.query.token };
        OAuth2.saveTempToken(token);
        let user = await this.setUserByToken(token);
        if (!user) {
          performLogout();
          response.navigate(`${window.location.origin}/auth/unauthorized`);
          return;
        }

        this.initRequestUser(request, user);
        response.navigate(newUrl);
      } else {
        /* Oauth2 authorization */
        // authorize application if code parameter is present
        let oauthClient = new OAuth2(
          request.absoluteUrl.replace(/&code=[^&]+/, '').replace(/\?code=[^&]+/, '')
        );
        let code = request.query.code;
        if (code) {
          let token = await oauthClient.tokenByCode(request);
          OAuth2.saveToken(token);
        }

        let cookieToken = OAuth2.loadToken();

        //try to load user from local storage if access token is valid
        if (!request.user && cookieToken) {
          let storedUser = localStorage.getItem('__user');
          if (storedUser) {
            try {
              storedUser = JSON.parse(storedUser);
            } catch (err) {
              storedUser = null;
            }
          }

          // user token is valid
          if (storedUser && storedUser.token === cookieToken) {
            if (storedUser.isActive) {
              this.initRequestUser(request, storedUser);
              return;
            } else {
              performLogout();
              return;
            }
          }

          // user fetch failed or invalid token
          localStorage.removeItem('__user');
          localStorage.removeItem('__customerUser');
          OAuth2.removeUserInfoToken();

          let token = OAuth2.loadToken();
          let user = await this.setUserByToken(token);
          if (user) {
            this.initRequestUser(request, user);
          } else {
            performLogout();
          }
        }
      }
    }
  }
}
