import React from 'react';

import { views } from 'outlinejs';
import { AeLoader, AeMarkupText } from '@photosi/albumepoca-ui';
import { conf } from 'outlinejs';

import './styles/main.scss';

export class ThankYouView extends views.BaseView {
  constructor(props) {
    super(props);
  }

  render() {
    let cartUrl = `${conf.settings.CART_BASE_URL}cart/${this.request.query['projectId']}`; //eslint-disable-line
    return (
      <div className="container">
        <AeLoader active={this.props.initViewRendering} />
        <div className="row thankyou">
          <div className="col-md-4">
            <h2 className="page__title">{this.i18n.gettext('Thank you')}</h2>
          </div>
          <div className="col-md-8">
            <div className="content pull-left">
              <p>
                <AeMarkupText
                  text={this.i18n.gettext(
                    "Confermiamo la ricezione delle immagini per la creazione dell'impaginato.  \r**Entro 48 ore** potrai visionare la nostra proposta di impaginazione."
                  )}
                />
              </p>
              <p>
                {this.i18n.gettext('Il tuo prodotto è presente nel ')}
                <a href={cartUrl}>
                  <strong>{this.i18n.gettext('carrello')}</strong>
                </a>
                {this.i18n.gettext(
                  ' ma non potrai modificarlo fino a quando il processo di impaginazione non sarà terminato.'
                )}
              </p>
              <p>
                {this.i18n.gettext(
                  "Abbiamo inviato un'email di riepilogo al tuo indirizzo di posta elettronica."
                )}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
