import Logger from '../core/logger';
import {
  AlbumProjectStorage,
  EventbookProjectStorage,
  MattedPrintsProjectStorage,
  UsbDriveProjectStorage,
  FrameProjectStorage
} from '../core/storage/index';

export async function getProjectName(productType) {
  let projectName = null;

  try {
    if (productType === 'Album') {
      projectName = await AlbumProjectStorage.getName();
    } else if (productType === 'EventBook') {
      projectName = await EventbookProjectStorage.getName();
    } else if (productType === 'MattedPrints') {
      projectName = await MattedPrintsProjectStorage.getName();
    } else if (productType === 'UsbDrive') {
      projectName = await UsbDriveProjectStorage.getName();
    } else if (productType === 'HomeDecor') {
      projectName = await FrameProjectStorage.getName();
    } else {
      throw new Error(`Project Storage not available for product ${productType}`);
    }
  } catch (err) {
    Logger.error(`Unable to get project name for ${productType}`, { error: err });
  }

  return projectName;
}

export async function setProjectName(value, productType) {
  try {
    if (productType === 'Album') {
      await AlbumProjectStorage.setName(value);
    } else if (productType === 'EventBook') {
      await EventbookProjectStorage.setName(value);
    } else if (productType === 'MattedPrints') {
      await MattedPrintsProjectStorage.setName(value);
    } else if (productType === 'UsbDrive') {
      await UsbDriveProjectStorage.setName(value);
    } else if (productType === 'HomeDecor') {
      await FrameProjectStorage.setName(value);
    } else {
      throw new Error(`Project Storage not available for product ${productType}`);
    }
  } catch (err) {
    Logger.error(`Unable to set project name for ${productType}`, { error: err });
  }
}
