import { components } from 'outlinejs';
import classNames from 'classnames';
import React from 'react';

export class SelectButton extends components.BaseComponent {
  handleSelectClick(event) {
    event.preventDefault();
    this.props.handleSelect();
  }

  render() {
    const { customButton } = this.props;
    const isActive = classNames('btn', 'btn-primary', {
      active: this.props.isSelected
    });

    return (
      <div>
        {!customButton && (
          <a
            href="#"
            className={isActive}
            onClick={this.handleSelectClick.bind(this)}
            data-action={this.props.action}>
            {this.props.btnText}
          </a>
        )}
        {customButton && (
          <a href="#" onClick={this.handleSelectClick.bind(this)} data-action={this.props.action}>
            <img src={this.props.btnImageUrl} />
          </a>
        )}
      </div>
    );
  }
}
