import React from 'react';
import PropTypes from 'prop-types';
import { AeMarkupText } from '@photosi/albumepoca-ui';
import { components } from 'outlinejs';

import { SelectButton } from './selectButton';

import RtpBigImg from '../media/images/rtp_big.jpg';
import RtpImg from '../media/images/rtp.jpg';

export class RtpServiceSection extends components.BaseComponent {
  constructor(props) {
    super(props);
    this.onSelect = this.onSelect.bind(this);
    this.onShowMore = this.onShowMore.bind(this);
  }

  get showMoreTitle() {
    return this.i18n.gettext('Design by yourself');
  }

  get showMoreContent() {
    return (
      <div className="impagination__modal">
        <div className="row">
          <div className="col-sm-12">
            <div className="impagination__modal__text">
              <strong>{this.i18n.gettext('Impagini con i programmi che usi abitualmente')}</strong>
              <br />
              {this.i18n.gettext(
                'Non modifichi il tuo attuale flusso di lavoro e ci invii i file già impaginati'
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 text-center">
            <div className="impagination__modal__picture">
              <img src={RtpBigImg} alt={'ReadyToPrint'} />
              <div className="timeline__content">
                <div className="timeline__title">
                  <div className="timeline__item timeline__item--you">
                    {this.i18n.gettext('Tu')}
                  </div>
                  <div className="timeline__item timeline__item--we">
                    {this.i18n.gettext('Noi')}
                  </div>
                </div>
                <div className="timeline__pins">
                  <div className="timeline__item timeline__item--you">
                    <div className="timeline__action">
                      {this.i18n.gettext('Invio dell’impaginato in alta risoluzione')}
                    </div>
                  </div>
                  <div className="timeline__item timeline__item--we">
                    <div className="timeline__action">{this.i18n.gettext('Stampa e Consegna')}</div>
                  </div>
                </div>
                <div className="timeline__arrow" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  onSelect() {
    const { selectService, code } = this.props;
    selectService(code);
  }

  onShowMore() {
    const { showMore } = this.props;
    showMore(this.showMoreContent, this.showMoreTitle);
  }

  render() {
    const { selected, code } = this.props;

    return (
      <div className="col-md-4">
        <div className={selected ? 'switchers__item active' : 'switchers__item'}>
          <div className="switchers__image">
            <img src={RtpImg} alt={'ReadyToPrint'} />
          </div>
          <div className="switchers__text">
            <div className="fix_min_height text-left">
              <p>
                <strong className={'text-uppercase'}>
                  <AeMarkupText
                    text={this.i18n.gettext(
                      'Carica i file in alta risoluzione  \r impaginati da te.'
                    )}
                  />
                </strong>
                <span
                  dangerouslySetInnerHTML={{
                    __html: this.i18n.gettext(
                      'Impagini con i programmi che usi   \r abitualmente e ci invii i file'
                    )
                  }}
                />
              </p>
            </div>
            <div className="hidden-divider" />
            <div className="color_correction" />
          </div>
          <div className="switchers__actions">
            <form>
              <SelectButton
                isSelected={selected}
                handleSelect={this.onSelect}
                btnText={this.i18n.gettext('Scegli')}
                action={`set_service_${code}`}
              />
            </form>
          </div>
        </div>
        <a onClick={this.onShowMore}>{this.i18n.gettext('Approfondisci')}</a>
      </div>
    );
  }
}

RtpServiceSection.propTypes = {
  code: PropTypes.string,
  selected: PropTypes.bool,
  selectService: PropTypes.func,
  showMore: PropTypes.func
};
