import React from 'react';

import { views } from 'outlinejs';
import { components } from 'outlinejs';
import { AeLoader } from '@photosi/albumepoca-ui';
import Slider from './components/styleSlider';
import DatePicker, { registerLocale } from 'react-datepicker';
import dateFnsIt from 'date-fns/locale/it/index';
import dateFnsEs from 'date-fns/locale/es/index';
import dateFnsEn from 'date-fns/locale/en-US/index';

import './styles/main.scss';

registerLocale('it', dateFnsIt);
registerLocale('es', dateFnsEs);
registerLocale('en', dateFnsEn);

export default class StyleItem extends components.BaseComponent {
  constructor(props) {
    super(props);
  }

  render() {
    let ctaContent = this.props.choosen
      ? this.i18n.gettext('Choosen')
      : this.i18n.gettext('Choose');
    let normalizedImagesUrls = [];
    this.props.images.map((image) => {
      return normalizedImagesUrls.push(image.url);
    });
    var greenLabelStyle = {
      color: '#90ab50'
    };
    var orangeLabelStyle = {
      color: '#ff9902'
    };
    let titleLabel = this.props.isNew ? (
      <span style={orangeLabelStyle}>{this.i18n.gettext('NOVITÀ')}</span>
    ) : (
      <span style={greenLabelStyle}>{this.i18n.gettext('AGGIORNATO')}</span>
    );
    return (
      <div className="col-md-6 style-item" id={this.props.styleId.toUpperCase()}>
        <div className="style-slider">
          <Slider ready={true} images={normalizedImagesUrls} />
        </div>
        <span>{this.props.name} - </span>
        {titleLabel}
        <p>{this.props.description}</p>
        <a className="btn btn-primary" onClick={this.props.onChoose}>
          <span>{ctaContent}</span>
        </a>
      </div>
    );
  }
}

export class StylesView extends views.BaseView {
  constructor(props) {
    super(props);
    this.state = {
      firstNameValue: '',
      secondNameValue: '',
      locationValue: '',
      startDate: null,
      choosenStyle: null,
      dateChoosen: false,
      showErrors: false,
      errors: []
    };

    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleOnBlur = this.handleOnBlur.bind(this);

    this.errorElement = null;
  }

  async UNSAFE_componentWillReceiveProps() {
    if (this.props.productConfiguration) {
      let parsedDate = null;
      if (this.props.productConfiguration.eventDate) {
        parsedDate = new Date(this.props.productConfiguration.eventDate);
        this.setState({ dateChoosen: true });
      }

      this.setState({
        firstNameValue: this.props.productConfiguration.firstName || '',
        secondNameValue: this.props.productConfiguration.secondName || '',
        locationValue: this.props.productConfiguration.eventLocation || '',
        startDate: parsedDate,
        choosenStyle: this.props.productConfiguration.eventStyle
      });
    }
  }

  checkErrors(errors, filter) {
    if (errors.length > 0) {
      let newErrors = errors.filter((error) => {
        return error.code !== filter;
      });
      this.setState({ errors: newErrors });
    }
  }

  handleDateChange(date) {
    const offsetDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000);

    this.setState({
      startDate: offsetDate,
      dateChoosen: true
    });
    let oldErrors = this.state.errors;
    this.checkErrors(oldErrors, 'date');
  }

  handleChange(key, event) {
    this.setState({ [key]: event.target.value });
  }

  async handleClick(code) {
    //Data validation
    let hasErrors = await this.validateData();
    if (hasErrors) {
      let div = this.errorElement.querySelectorAll('.alert-danger');
      let clickedElement = document.getElementById(code);
      let yToScroll =
        clickedElement.getBoundingClientRect().top + document.documentElement.scrollTop;
      let scrollToDiv = div[0];
      scrollToDiv.scrollIntoView({ block: 'end', behavior: 'smooth' });
      window.scrollBy(0, -yToScroll);
      return;
    }

    this.setState({ choosenStyle: code });

    let formData = {
      firstName: this.state.firstNameValue,
      secondName: this.state.secondNameValue,
      eventLocation: this.state.locationValue,
      eventDate: this.state.startDate,
      eventStyle: this.state.choosenStyle
    };

    await this.delegate.setConfirmData(formData);
    await this.delegate.updateConfiguration();
  }

  async validateData() {
    let errors = [];
    if (!this.state.dateChoosen || !this.state.startDate) {
      this.setState({ showErrors: true });
      errors.push({
        message: this.i18n.gettext('It is necessary to choose the event date'),
        code: 'date'
      });
    }
    this.setState({ errors: errors });
    return errors.length > 0;
  }

  async handleOnBlur() {
    let hasErrors = await this.validateData();
    this.checkErrors(hasErrors, 'style');
  }

  renderField(id, label, field) {
    const dateLayout = (
      <div className="col-md-3 date-layout">
        <label htmlFor={id} className="col-md-4 control-label">
          {label}
        </label>
        <div className="col-md-8">
          {field}
          <i className="fa fa-2x fa-calendar" />
        </div>
      </div>
    );

    const genericLayout = (
      <div className="col-md-3">
        <label htmlFor={id} className="col-md-4 control-label">
          {label}
        </label>
        <div className="col-md-8">{field}</div>
      </div>
    );

    const layout = id === 'eventDate' ? dateLayout : genericLayout;
    return layout;
  }

  renderTextInput(id, label, key) {
    return this.renderField(
      id,
      label,
      <input
        type="text"
        className="form-control"
        id={id}
        value={this.state[key]}
        onChange={this.handleChange.bind(this, key)}
      />
    );
  }

  renderDatePicker(id, label) {
    return this.renderField(
      id,
      label,
      <DatePicker
        locale={this.request.language}
        dateFormat={'dd/MM/yyyy'}
        selected={this.state.startDate}
        onChange={this.handleDateChange}
        onBlur={this.handleOnBlur}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
      />
    );
  }

  renderStyles() {
    let styles = [];
    if (this.props.stylesCollection && this.props.stylesCollection.length > 0) {
      styles = this.delegate.stylesCollection.map((style) => {
        return (
          <div
            key={style.name}
            className={this.state.choosenStyle === style.id ? 'isChoosen' : null}>
            <StyleItem
              key={style.id}
              name={style.name}
              styleId={style.id}
              description={style.description}
              images={style.images}
              isNew={style.isNew}
              onChoose={this.handleClick.bind(this, style.id)}
              choosen={this.state.choosenStyle === style.id}
            />
          </div>
        );
      });
    }
    return styles;
  }

  renderErrors() {
    let errors = (
      <div
        className="errors"
        ref={(div) => {
          this.errorElement = div;
        }}
      />
    );
    if (this.state.showErrors) {
      errors = (
        <div
          className="row errors"
          ref={(div) => {
            this.errorElement = div;
          }}>
          {this.state.errors.map((error) => {
            let idErrorCode = error.code || this.name;

            return (
              <div key={error.code} id={idErrorCode} className="alert alert-danger" role="alert">
                <i className="fa fa-exclamation-circle" aria-hidden="true" />
                &nbsp;
                <span className="sr-only">{this.i18n.gettext('Errore:')}</span>
                {error.message}
              </div>
            );
          })}
        </div>
      );
    }
    return errors;
  }

  render() {
    let formInstance = (
      <div className="row design-styles__confirm">
        <div className="col-md-12">
          <h2>{this.i18n.gettext('Enter the data and choose the layout style of your event')}</h2>
        </div>
        {this.i18n.gettext(
          'Please carefully fill in the fields below with the details of your event and choose the layout style that best represents you.'
        )}
        <div className="col-md-12 form-horizontal design-styles__form">
          {this.renderDatePicker('eventDate', this.i18n.gettext('Event date'))}
          {this.renderTextInput('firstName', this.i18n.gettext('Name 1'), 'firstNameValue')}
          {this.renderTextInput('secondName', this.i18n.gettext('Name 2'), 'secondNameValue')}
          {this.renderTextInput('location', this.i18n.gettext('Location'), 'locationValue')}
        </div>
      </div>
    );

    let errorsInstance = this.renderErrors();

    let stylesInstance = (
      <div className="row styles-container">
        {this.props.initViewRendering ? null : this.renderStyles()}
      </div>
    );

    return (
      <div className="container">
        <AeLoader active={this.props.initViewRendering || this.props.loadingPage} />
        {formInstance}
        {errorsInstance}
        {stylesInstance}
      </div>
    );
  }
}
