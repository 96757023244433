import OAuth2 from './oauth';

import { cleanAllConfigurators } from '../core/storage/cleaners';
import EventTracker from '../core/eventTracker';

export async function performLogout() {
  await cleanAllConfigurators();
  await OAuth2.cleanToken();
  localStorage.removeItem('__user');
  localStorage.removeItem('__customerUser');
  EventTracker.removeTrackingForUser();
}
