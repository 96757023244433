import { BaseModel, mergeBackboneOptions } from '../core/models';
import { conf } from 'outlinejs';

export class DuplicateProject extends BaseModel {
  get id() {
    return this.get('id');
  }

  get canBeOrdered() {
    return this.get('canBeOrdered');
  }

  fetch(options = {}) {
    let params = {
      data: { softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE, includeValidation: true }
    };
    options = mergeBackboneOptions(options, params);
    return super.fetch(options);
  }
}

export class DuplicateCompatibleProduct extends BaseModel {
  get code() {
    return this.get('code');
  }

  get duplicateCode() {
    return this.get('duplicateCode');
  }

  get productType() {
    return this.get('productType');
  }

  fetch(options = {}) {
    let params = {
      data: { softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE, includeValidation: true }
    };
    options = mergeBackboneOptions(options, params);
    return super.fetch(options);
  }
}
