import React from 'react';
import PropTypes from 'prop-types';
import { components } from 'outlinejs';
import { AeMarkupText } from '@photosi/albumepoca-ui';

import CheckboxOnImg from '../media/images/checkbox_on.jpg';
import CheckboxOffImg from '../media/images/checkbox_off.jpg';

export class ColorCorrectionForm extends components.BaseComponent {
  constructor(props) {
    super(props);

    this.handleClick = this.handleClick.bind(this);
  }

  handleClick(changeEvent) {
    const { handleColorCorrectionChange } = this.props;
    handleColorCorrectionChange(changeEvent.target.value);
  }

  render() {
    const { inputId, serviceColorCorrections, colorCorrectionCode } = this.props;
    return (
      <div>
        <div className="text-left">
          <p>
            <strong className={'text-uppercase'}>
              <AeMarkupText text={this.i18n.gettext('Correzione colore')} />
            </strong>
            <AeMarkupText
              text={this.i18n.gettext(
                'Un esperto controlla ogni immagine e   \r bilanciamento luminosità ed il colore'
              )}
            />
          </p>
        </div>
        <div className="switchers__price">
          <form>
            <div className="custom-radio col-md-4 text-left">
              <label htmlFor={`${inputId}1`} className="text-uppercase">
                <input
                  id={`${inputId}1`}
                  type="radio"
                  value={serviceColorCorrections[0].code}
                  checked={colorCorrectionCode === serviceColorCorrections[0].code}
                  onChange={(data) => this.handleClick(data)}
                />
                <img
                  src={
                    colorCorrectionCode === serviceColorCorrections[0].code
                      ? CheckboxOnImg
                      : CheckboxOffImg
                  }
                  alt={serviceColorCorrections[0].code}
                />
                {this.i18n.gettext('si')}
              </label>
            </div>
            <div className="custom-radio col-md-4 text-left">
              <label htmlFor={`${inputId}2`} className="text-uppercase">
                <input
                  id={`${inputId}2`}
                  type="radio"
                  value={serviceColorCorrections[1].code}
                  checked={colorCorrectionCode === serviceColorCorrections[1].code}
                  onChange={(data) => this.handleClick(data)}
                />
                <img
                  src={
                    colorCorrectionCode === serviceColorCorrections[1].code
                      ? CheckboxOnImg
                      : CheckboxOffImg
                  }
                  alt={serviceColorCorrections[1].code}
                />
                {this.i18n.gettext('no')}
              </label>
            </div>
          </form>
          <span
            className={
              colorCorrectionCode === serviceColorCorrections[0].code ? 'pull-right' : 'hidden'
            }>
            + {serviceColorCorrections[0].formattedPrice}
          </span>
        </div>
      </div>
    );
  }
}

ColorCorrectionForm.propTypes = {
  colorCorrectionCode: PropTypes.string,
  inputId: PropTypes.string,
  handleColorCorrectionChange: PropTypes.func,
  serviceColorCorrections: PropTypes.array
};
