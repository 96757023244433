import { conf } from 'outlinejs';
import { runtime } from '@outlinejs/contexts';
import { controllers } from 'outlinejs';

import { BaseAEController } from '../core/controllers';

import OAuth2 from './oauth';
import { UnauthorizedView } from './views';
import { performLogout } from './utils';
import { BaseLoadingView } from '../core/views';
import { navigateTo } from '../core/utils/index';

export class LoginController extends controllers.BaseController {
  static get loginRequired() {
    return false;
  }

  get view() {
    return BaseLoadingView;
  }

  init() {
    if (runtime.isClient) {
      if (this.request.query['next-url'] && this.request.user) {
        navigateTo(this.request, this.response, this.request.query['next-url'], {}, true);
      } else {
        var oauthClient = new OAuth2(this.request.absoluteUrl);
        oauthClient.authorizationCodeGrant(this.response);
      }
    } else {
      this.render();
    }
  }
}

export class LogoutController extends controllers.BaseController {
  static get loginRequired() {
    return false;
  }

  get view() {
    return BaseLoadingView;
  }

  async init() {
    if (runtime.isClient) {
      performLogout();

      let nextUrl = conf.settings.HOMEPAGE_BASE_URL;

      let logoutUrl = `${conf.settings.OAUTH2_LOGOUT_URL}?next=${nextUrl}`;

      navigateTo(this.request, this.response, logoutUrl, {}, true);
    } else {
      this.render();
    }
  }
}

export class UnauthorizedController extends BaseAEController {
  static get loginRequired() {
    return false;
  }

  get context() {
    return {};
  }

  get view() {
    return UnauthorizedView;
  }

  init() {
    this.render(this.context);
  }
}
