import React, { Component } from 'react';

const Arrow = ({ name, arrowClick, disabled }) => {
  return (
    <div className={`${name}Arrow pull-${name} ${disabled}`} onClick={() => arrowClick()}>
      <span className={`fa fa-chevron-${name} text-center`}></span>
    </div>
  );
};

const Arrows = ({ leftArrowClick, rightArrowClick, isDisabled }) => {
  let leftArrowDisabled = isDisabled === 'leftIsDisabled' ? 'isDisabled' : '';
  let rightArrowDisabled = isDisabled === 'rightIsDisabled' ? 'isDisabled' : '';
  return (
    <div className="arrows-container">
      <Arrow name="left" arrowClick={leftArrowClick} disabled={leftArrowDisabled} />
      <Arrow name="right" arrowClick={rightArrowClick} disabled={rightArrowDisabled} />
    </div>
  );
};

const Slide = (props) => {
  const current = props.background[props.current];

  const styles = {
    imageBackground: {
      backgroundImage: `url(${current})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center center'
    }
  };
  return <div className="slide" style={styles.imageBackground}></div>;
};

export default class Slider extends Component {
  constructor(props) {
    super(props);

    this.state = {
      background: this.props.images,
      current: 0,
      ready: true
    };

    this.previousSlide = this.previousSlide.bind(this);
    this.nextSlide = this.nextSlide.bind(this);
    this.dotClick = this.dotClick.bind(this);
    this.preloadNextImage = this.preloadNextImage.bind(this);
    this.leftArrowClick = this.leftArrowClick.bind(this);
    this.rightArrowClick = this.rightArrowClick.bind(this);
  }

  preloadNextImage() {
    const current = this.state.current;
    const background = this.state.background;

    if (current !== undefined && current < background.length - 1) {
      return (
        <div
          style={{
            display: 'none',
            height: '100%',
            backgroundImage: `url(${this.state.background[this.state.current + 1]})`
          }}
        />
      );
    } else {
      return null;
    }
  }

  /*
   * SLIDER AUTOPLAY
   */
  // componentDidMount() {
  //
  //   setInterval(() => {
  //     if (this.state.ready) {
  //       this.nextSlide();
  //     }
  //   }, 8000);
  // }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.ready) {
      this.setState({ current: 0, ready: true });
    }
  }

  render() {
    let arrowDisabled = '';
    if (this.state.current === 0) {
      arrowDisabled = 'leftIsDisabled';
    } else if (this.state.current === this.state.background.length - 1) {
      arrowDisabled = 'rightIsDisabled';
    }
    return (
      <div className="slider">
        {/* The Current Image*/}
        {this.state.ready ? (
          <Slide
            background={this.state.background}
            current={this.state.current}
            ready={this.state.ready}
          />
        ) : null}
        <Arrows
          leftArrowClick={this.leftArrowClick}
          rightArrowClick={this.rightArrowClick}
          isDisabled={arrowDisabled}
        />
        {this.preloadNextImage()}
      </div>
    );
  }

  /* Handle cLicking on left arrows */
  leftArrowClick() {
    this.previousSlide();
  }

  /* Handle cLicking on left arrows */
  rightArrowClick() {
    this.nextSlide();
  }

  /* Handle cLicking of dots */
  dotClick(dotIndex) {
    this.setState({ current: dotIndex });
  }

  /* Previous & Next Slide Functionality */
  previousSlide() {
    let current = this.state.current;
    let imageArray = this.state.background.length - 1;

    if (current >= 1) {
      this.setState({ current: current - 1 });
    }
    if (current === 0) {
      this.setState({ current: imageArray });
    }
  }

  nextSlide() {
    let current = this.state.current;
    let imageArray = this.state.background.length - 1;

    if (current >= 0 && current !== imageArray) {
      this.setState({ current: current + 1 });
    }
    if (current === imageArray) {
      this.setState({ current: 0 });
    }
  }
}
