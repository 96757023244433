import { routers, routing } from 'outlinejs';

import { SetShopCodeController, RemoveShopCodeController } from './controllers';

export default class extends routers.BaseRouter {
  static get urlPatterns() {
    return {
      '': routing.url('set-shop-code:main', SetShopCodeController),
      'set-shop-code': routing.url('set-shop-code:main', SetShopCodeController),
      'remove-shop-code': routing.url('remove-shop-code:main', RemoveShopCodeController)
    };
  }
}
