import React from 'react';
import PropTypes from 'prop-types';
import { AeMarkupText } from '@photosi/albumepoca-ui';
import { components } from 'outlinejs';

import { SelectButton } from './selectButton';

import { ColorCorrectionForm } from './colorCorrectionForm';
import MattedPrintsImg from '../media/images/mattedprints.png';
import EVImg from '../media/images/ev.png';

export class MattedPrintServiceSection extends components.BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      aeveloceModal: false
    };

    this.onSelect = this.onSelect.bind(this);
  }

  onSelect() {
    const { selectService, code, disabled } = this.props;
    if (disabled) {
      return false;
    }
    selectService(code);
  }

  render() {
    const { selected, serviceColorCorrections, colorCorrectionCode, handleColorCorrectionChange } =
      this.props;

    return (
      <div className="col-md-12">
        <div className="switchers__item matted-prints">
          <div className="row two_column_padding">
            <div className="col-md-6">
              <div className="left_switchers__image">
                <img src={MattedPrintsImg} alt={'MattedPrints'} />
              </div>
            </div>
            <div className="col-md-6 left_divider">
              <div className="right_switchers__image">
                <img src={EVImg} alt={'EditorResponsive'} />
              </div>
              <div className="switchers__text">
                <div className="fix_min_height text-left">
                  <p>
                    <strong className={'text-uppercase'}>
                      <AeMarkupText text={this.i18n.gettext('Impagina online')} />
                    </strong>
                    <AeMarkupText
                      text={this.i18n.gettext(
                        'Carichi le immagini in alta risoluzione e   \r crei la tua impaginazione online con il programma AE Veloce'
                      )}
                    />
                  </p>
                </div>
                {serviceColorCorrections && serviceColorCorrections.length && (
                  <ColorCorrectionForm
                    serviceColorCorrections={serviceColorCorrections}
                    colorCorrectionCode={colorCorrectionCode}
                    inputId={'aeVeloceColorCorrection'}
                    handleColorCorrectionChange={handleColorCorrectionChange}
                  />
                )}
              </div>
              <div className="switchers__actions">
                <SelectButton
                  isSelected={selected}
                  handleSelect={this.onSelect}
                  btnText={this.i18n.gettext('Apri editor')}
                  action={`set_service_MattedPrints`}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

MattedPrintServiceSection.propTypes = {
  code: PropTypes.string,
  selected: PropTypes.bool,
  selectService: PropTypes.func,
  colorCorrectionCode: PropTypes.string,
  handleColorCorrectionChange: PropTypes.func,
  serviceColorCorrections: PropTypes.array
};
