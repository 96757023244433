import React from 'react';
import { conf, views } from 'outlinejs';
import { AeLoader, AeNotify } from '@photosi/albumepoca-ui';

import { LayoutServicesCodes } from './utils';

import { ShowMoreDialog } from './components/showMoreDialog';
import { RtpServiceSection } from './components/rtpServiceSection';
import { AeVeloceServiceSection } from './components/aeVeloceServiceSection';
import { FsdServiceSection } from './components/fsdServiceSection';
import { MattedPrintServiceSection } from './components/mattedPrintServiceSection';

import './styles/main.scss';

export class ServicesContentView extends views.BaseView {
  constructor(props) {
    super(props);

    this.state = {
      selected: '',
      dynamicText: false,
      showDialog: false,
      showNotify: false,
      disableService: false,
      aeVeloceColorCorrection: '',
      fullServiceDesignColorCorrection: ''
    };

    this.serviceSelected = this.serviceSelected.bind(this);
    this.showModal = this.showModal.bind(this);
    this.closeModal = this.closeModal.bind(this);

    this.handleAeVeloceColorCorrectionChange = this.handleAeVeloceColorCorrectionChange.bind(this);
    this.handleFullServiceDesignColorCorrectionChange =
      this.handleFullServiceDesignColorCorrectionChange.bind(this);
  }

  componentDidMount() {
    if (this.request.query.showNotify) {
      this.setState({
        showNotify: true
      });
      window.scrollTo(0, 0);
    }
  }

  UNSAFE_componentWillReceiveProps() {
    if (this.props.delegate.services) {
      this.props.delegate.services.forEach((item) => {
        if (item.colorCorrections.length) {
          if (
            item.code === LayoutServicesCodes.aeVeloceCode &&
            this.state.aeVeloceColorCorrection === ''
          ) {
            this.setState({
              aeVeloceColorCorrection: item.colorCorrections[0].code
            });
          }
          if (
            item.code === LayoutServicesCodes.fullServiceDesignCode &&
            this.state.fullServiceDesignColorCorrection === ''
          ) {
            this.setState({
              fullServiceDesignColorCorrection: item.colorCorrections[0].code
            });
          }
        }
      });
    }

    if (this.delegate.formatType === '50x20' || this.delegate.formatType === '25x10') {
      this.setState({
        disableService: true
      });
    }
  }

  serviceSelected(serviceCode) {
    let colorCorrection = null;
    if (serviceCode === LayoutServicesCodes.aeVeloceCode) {
      colorCorrection = this.state.aeVeloceColorCorrection;
    }
    if (serviceCode === LayoutServicesCodes.fullServiceDesignCode) {
      colorCorrection = this.state.fullServiceDesignColorCorrection;
    }
    this.delegate.selectService(serviceCode, colorCorrection);
    this.setState({ selected: serviceCode });
  }

  showModal(template, title) {
    this.setState({
      showDialog: true,
      dinamicPart: template,
      dinamicTitle: title,
      dynamicText: true
    });
  }

  closeModal() {
    this.setState({
      showDialog: false,
      dynamicText: false
    });
  }

  closeNotification() {
    this.setState({
      showNotify: false
    });
  }

  handleAeVeloceColorCorrectionChange(code) {
    this.setState({
      aeVeloceColorCorrection: code
    });
  }

  handleFullServiceDesignColorCorrectionChange(code) {
    this.setState({
      fullServiceDesignColorCorrection: code
    });
  }

  render() {
    const { dinamicTitle, dinamicPart, dynamicText, showDialog, selected, disableService } =
      this.state;
    let separator = <div className="separator" />;
    let modalClasses = {
      'modal-lg': true,
      'text-center': true,
      'custom-modal': true
    };

    let readyToprintServices;
    let aeVeloceServices;
    let fullServiceDesignServices;

    if (this.props.delegate.services) {
      readyToprintServices = this.props.delegate.services.find(
        (item) => item.code === LayoutServicesCodes.readyToPrintCode
      );
      aeVeloceServices = this.props.delegate.services.find(
        (item) => item.code === LayoutServicesCodes.aeVeloceCode
      );
      fullServiceDesignServices = this.props.delegate.services.find(
        (item) => item.code === LayoutServicesCodes.fullServiceDesignCode
      );
    }

    let impaginationInstruction = this.i18n.gettext(
      'I file caricati devono essere in formato JPG, con una risoluzione minima di 254 dpi e profilo colore sRGB IEC61966-2.1. Se correggi in autonomia le foto, scarica <a href="{DOWNLOAD_COLOR_PROFILE_URL}" download><b>{DOWNLOAD_COLOR_PROFILE_TEXT}</b></a> i nostri profili colore per simulare i colori della carta stampata sul tuo monitor.<br/> Se carichi gli impaginati o richiedi il nostro servizio di impaginazione, rinomina i tuoi file utilizzando il suffisso _001, _002, ecc. in modo da garantire la corretta sequenza temporale delle immagini. Scarica <a href="{DOWNLOAD_TEMPLATE_URL}" target="_blank" download><b>{DOWNLOAD_TEMPLATE_TEXT}</b></a> le regole da rispettare per impaginare correttamente i nostri prodotti qualunque sia il software che usi.<br/>Inoltre puoi accedere ai nostri template di Photoshop <a href="{DOWNLOAD_RTP_TEMPLATE_URL}" target="_blank" download><b>{DOWNLOAD_RTP_TEMPLATE_TEXT}</b></a>'
    );
    const colorProfileButtonLabel = this.i18n.gettext('qui');
    const templateButtonLabel = this.i18n.gettext('Qui');
    const rtpTemplateButtonLabel = this.i18n.gettext('qui');
    impaginationInstruction = impaginationInstruction.replace(
      '{DOWNLOAD_COLOR_PROFILE_URL}',
      conf.settings.DOWNLOAD_COLOR_PROFILE_URL[this.request.language]
    );
    impaginationInstruction = impaginationInstruction.replace(
      '{DOWNLOAD_COLOR_PROFILE_TEXT}',
      colorProfileButtonLabel
    );
    impaginationInstruction = impaginationInstruction.replace(
      '{DOWNLOAD_TEMPLATE_URL}',
      conf.settings.DOWNLOAD_TEMPLATE_URL[this.request.language]
    );
    impaginationInstruction = impaginationInstruction.replace(
      '{DOWNLOAD_TEMPLATE_TEXT}',
      templateButtonLabel
    );
    impaginationInstruction = impaginationInstruction.replace(
      '{DOWNLOAD_RTP_TEMPLATE_URL}',
      conf.settings.DOWNLOAD_RTP_TEMPLATE_URL[this.request.language]
    );
    impaginationInstruction = impaginationInstruction.replace(
      '{DOWNLOAD_RTP_TEMPLATE_TEXT}',
      rtpTemplateButtonLabel
    );

    let content = (
      <div className="row confirm__message">
        <div className="col-sm-12 text-center">
          <div className="row">
            <h2 className="page__title">{this.i18n.gettext('Scegli come impaginare')}</h2>
          </div>
          <div className="impagination">
            <div className="impagination__row">
              <div className="impagination__group">
                <span>
                  {this.i18n.gettext(
                    "Scegli se caricare l'impaginato creato da te, utilizzare il nostro impaginatore online AE Veloce o affidare ad Album Epoca il processo di impaginazione."
                  )}
                </span>
              </div>
              <div className="col-sm-10 col-sm-offset-1">
                <div className="impagination__alert">
                  <div className="row">
                    <div className="col-lg-1 col-sm-1 col-xs-12 icon-holder">
                      <i className="fa fa-exclamation-triangle" />
                    </div>
                    <div className="col-lg-11 col-sm-11 col-xs-12 text-justify">
                      <b className="impagination-alert-title">
                        {this.i18n.gettext(
                          'PRESTA ATTENZIONE ALLE CARATTERISTICHE DEI FILE CHE UTILIZZI'
                        )}
                      </b>
                      <br />
                      <span dangerouslySetInnerHTML={{ __html: impaginationInstruction }} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-10 col-sm-offset-1">
              {readyToprintServices && (
                <RtpServiceSection
                  code={LayoutServicesCodes.readyToPrintCode}
                  selected={selected === LayoutServicesCodes.readyToPrintCode}
                  selectService={this.serviceSelected}
                  showMore={this.showModal}
                />
              )}
              {aeVeloceServices && (
                <AeVeloceServiceSection
                  language={this.request.language}
                  code={LayoutServicesCodes.aeVeloceCode}
                  colorCorrectionCode={this.state.aeVeloceColorCorrection}
                  serviceColorCorrections={aeVeloceServices.colorCorrections}
                  selected={selected === LayoutServicesCodes.aeVeloceCode}
                  disabled={disableService}
                  selectService={this.serviceSelected}
                  showMore={this.showModal}
                  handleColorCorrectionChange={this.handleAeVeloceColorCorrectionChange}
                />
              )}
              {fullServiceDesignServices && (
                <FsdServiceSection
                  code={LayoutServicesCodes.fullServiceDesignCode}
                  colorCorrectionCode={this.state.fullServiceDesignColorCorrection}
                  price={fullServiceDesignServices.formattedPrice}
                  serviceColorCorrections={fullServiceDesignServices.colorCorrections}
                  selected={selected === LayoutServicesCodes.fullServiceDesignCode}
                  disabled={disableService}
                  selectService={this.serviceSelected}
                  showMore={this.showModal}
                  handleColorCorrectionChange={this.handleFullServiceDesignColorCorrectionChange}
                />
              )}
              <ShowMoreDialog
                showDialog={showDialog}
                dialogTitle={dinamicTitle}
                dynamicPart={dinamicPart}
                dynamicText={dynamicText}
                modalClasses={modalClasses}
                separator={separator}
                closeCallback={this.closeModal}
              />
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div className="container">
        <AeLoader active={this.props.loadingPage || this.props.initViewRendering} />
        <AeNotify
          isActive={this.state.showNotify}
          message={this.i18n.gettext('Il tuo progetto è stato aggiunto al carrello')}
          onClick={this.closeNotification.bind(this)}
          onDismiss={this.closeNotification.bind(this)}
          action={'X'}
          dismissAfter={5000}
        />
        {this.props.initViewRendering ? null : content}
      </div>
    );
  }
}

export class MattedPrintsServicesContentView extends views.BaseView {
  constructor(props) {
    super(props);
    this.state = {
      aeVeloceColorCorrection: ''
    };

    this.serviceSelected = this.serviceSelected.bind(this);
    this.handleAeVeloceColorCorrectionChange = this.handleAeVeloceColorCorrectionChange.bind(this);
  }

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  serviceSelected(serviceCode, colorCorrection) {
    this.delegate.selectService(serviceCode, colorCorrection);
    this.setState({ selected: serviceCode });
  }

  UNSAFE_componentWillReceiveProps() {
    if (this.props.delegate.services) {
      this.props.delegate.services.map((item) => {
        if (item.colorCorrections.length) {
          if (
            item.code === LayoutServicesCodes.aeVeloceCode &&
            this.state.aeVeloceColorCorrection === ''
          ) {
            this.setState({
              aeVeloceColorCorrection: item.colorCorrections[0].code
            });
          }
        }
      });
    }
  }

  handleAeVeloceColorCorrectionChange(code) {
    this.setState({
      aeVeloceColorCorrection: code
    });
  }

  render() {
    const { aeVeloceColorCorrection } = this.state;

    let aeVeloceServices;
    if (this.props.delegate.services) {
      aeVeloceServices = this.props.delegate.services.find(
        (item) => item.code === LayoutServicesCodes.aeVeloceCode
      );
    }

    let content = (
      <div className="row confirm__message">
        <div className="col-sm-12 text-center">
          <div className="row">
            <h2 className="page__title">{this.i18n.gettext('Inquadra le foto')}</h2>
          </div>
          <div className="impagination">
            <div className="impagination__row">
              <div className="col-sm-10 col-sm-offset-1">
                <div className="impagination__alert">
                  <div className="row">
                    <div
                      className="col-lg-1 col-sm-1 col-xs-12 icon-holder"
                      style={{ paddingTop: '0' }}>
                      <i className="fa fa-exclamation-triangle" />
                    </div>
                    <div className="col-lg-11 col-sm-11 col-xs-12 text-justify">
                      <b className="impagination-alert-title">
                        {this.i18n.gettext(
                          'PRESTA ATTENZIONE ALLE CARATTERISTICHE DEI FILE CHE UTILIZZI'
                        )}
                      </b>
                      <br />
                      <span>
                        {this.i18n.gettext(
                          'Risoluzione minima richiesta 254 dpi. Profilo colore sRGB IEC61966-2.1.'
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-10 col-sm-offset-1">
              {aeVeloceServices && (
                <MattedPrintServiceSection
                  code={LayoutServicesCodes.aeVeloceCode}
                  colorCorrectionCode={aeVeloceColorCorrection}
                  serviceColorCorrections={aeVeloceServices.colorCorrections}
                  selectService={this.serviceSelected}
                  handleColorCorrectionChange={this.handleAeVeloceColorCorrectionChange}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );

    return (
      <div className="container">
        <AeLoader active={this.props.loadingPage || this.props.initViewRendering} />
        {this.props.initViewRendering ? null : content}
      </div>
    );
  }
}
