import { conf } from 'outlinejs';
import { runtime } from '@outlinejs/contexts';

import { BaseAEController } from '../core/controllers';

import Logger from '../core/logger';
import { GuiErrors, navigateTo } from '../core/utils/index';
import { eventBookFunnelSteps } from '../core/utils/breadcrumb';
import { albumFunnelSteps } from '../core/utils/breadcrumb';
import { mattedPrintsFunnelSteps } from '../core/utils/breadcrumb';
import { Project } from '../projects/models';
import { AlbumStorage } from '../album/utils';
import { EventBookConfiguration } from '../eventbook/models';
import { AlbumConfiguration } from '../album/models';
import { getProjectName } from '../projects/utils';

import { MattedPrintsServicesContentView, ServicesContentView } from './views';
import { LayoutServicesCodes } from './utils';
import { DefaultBlockProject, MattedPrintsProject } from './models';
import { MattedPrintsConfiguration } from '../matted-prints/models';
import { ProjectServicesCollection } from './managers';
import EventTracker, { productInterface } from '../core/eventTracker';

export class BaseServicesController extends BaseAEController {
  get funnelStep() {
    return 3;
  }

  get context() {
    return Object.assign(super.context, {
      user: this.user,
      customerUser: this.customerUser,
      loadingPage: this.loadingPage,
      initViewRendering: this.initViewRendering
    });
  }

  async initContentProps() {
    super.initContentProps();

    this.services = null;

    // page is loading
    this.loadingPage = false;
    this.user = {};
  }

  async getServices(projectId) {
    this.services = await new ProjectServicesCollection(projectId).fetch();
  }

  async init() {
    this.startInitialRendering();

    await this.initContentProps();

    if (runtime.isClient) {
      try {
        await Promise.all([
          this.getServices(this.request.query.professionalProjectId),
          this.configuration.fetch(),
          this.project.fetch()
        ]);
      } catch (err) {
        let timestamp = new Date().getTime();
        Logger.error('ServicesController.init - Failed', {
          error: err,
          errorCode: timestamp,
          projectId: this.project.id,
          configurationId: this.configuration.id,
          services: this.services
        });
        GuiErrors.modalError(
          this.i18n.gettext(
            'Non è stato possibile caricare la configurazione o il progetto. Ti preghiamo di riprovare.'
          ),
          'Error detail: ServicesController:init - code: ' + timestamp,
          null,
          null,
          this.gotoPreviusStep.bind(this)
        );
      }

      this.project.name = this.projectName;

      try {
        await this.project.save();
      } catch (err) {
        let timestamp = new Date().getTime();
        Logger.error('ServicesController.init - Project save failed', {
          error: err,
          errorCode: timestamp,
          projectId: this.project.id,
          name: this.projectName
        });
        GuiErrors.modalError(
          this.i18n.gettext('Non è stato possibile salvare il nome del progetto.'),
          'Error detail: ServicesController:init - code: ' + timestamp,
          null,
          null,
          this.gotoPreviusStep.bind(this)
        );
      }

      this.render(this.context);

      if (this.configuration.serviceConfigurationTypeCode) {
        this.jumpToNextPage();
        return;
      }

      EventTracker.log(
        this.customerUser,
        'design_service_selection_view',
        productInterface(this.configuration)
      );

      this.stopInitialRendering();
    }
  }

  async createEditorVeloceProject(language = 'en') {
    let projectName = await getProjectName(this.configuration.productType);
    let editorVeloceProject = new DefaultBlockProject({
      shopCode: this.customerUser.shopCode,
      language: language,
      productConfigurationId: this.configuration.id,
      productConfigurationType: this.configuration.productType,
      projectName: projectName
    });

    let maxRetryNumber = 4;
    let retryNumber = 1;
    // Retry logic to temporally avoid to api/backend problems
    do {
      try {
        editorVeloceProject = await editorVeloceProject.save();
      } catch (err) {
        Logger.error(
          `ServicesController.selectService - createEditorVeloceProject attempt number ${retryNumber} failed.`,
          {
            shopCode: this.customerUser.shopCode,
            language: language,
            productConfigurationId: this.configuration.id,
            productConfigurationType: this.configuration.productType,
            error: err
          }
        );
        retryNumber += 1;
        await this.delay();
      }
    } while (!editorVeloceProject.id && retryNumber <= maxRetryNumber);

    return editorVeloceProject;
  }

  redirectToCart() {
    let url = `${conf.settings.CART_BASE_URL}cart/${this.project.id}`;
    navigateTo(this.request, this.response, url, {}, true);
  }

  jumpToNextPage() {
    // default next state is the preview
    let nextState = 'layoutDispatch:' + this.projectType;

    /**
     // in the regular flux we arrive here from save preorder page.
     // if not we send the user to the packaging
     // in this case we already have a service selected, so the standard next state is the preview.
     // TO-DO: Questo non funziona, è da fixare o da analizzare megio. let previusLink = document.referrer; vale stringa vuota
     // but if we did not come from the preorders page we send the user directly to the packaging
     // this because we have to handle the back button and preserve the flux
     let preordersLink = RouteUtils.reverse('login');
     let previusLink = document.referrer;

     if (previusLink.indexOf(preordersLink) !== -1) {
      nextState = 'eventbook-packaging:main';
      this.response.navigate(nextState);
    }**/
    if (this.project && this.project.creationStatus && this.project.isDuplicationInProgress()) {
      // skip RTP preview if duplication is in progress (block )
      this.redirectToCart();
      return;
    }

    if (this.configuration.serviceConfigurationTypeCode === LayoutServicesCodes.aeVeloceCode) {
      // in AeVeloce normal flux customization we go to the shopping cart
      this.redirectToCart();
      return;
    }
    if (
      this.configuration.serviceConfigurationTypeCode ===
        LayoutServicesCodes.fullServiceDesignCode &&
      this.configuration.fullServiceDesignRequested
    ) {
      // Full Service Design is in progress, we should go to the shopping cart
      this.redirectToCart();
      return;
    }

    navigateTo(this.request, this.response, nextState, {}, true, this.request.query);
  }

  async saveConfiguration(serviceCode, colorCorrection) {
    this.startLoadingPage();

    // save service in configuration
    this.configuration.serviceConfigurationTypeCode = serviceCode;
    this.configuration.colorCorrectionCode = colorCorrection;

    let configuration;
    try {
      configuration = await this.configuration.save();
    } catch (err) {
      let timestamp = new Date().getTime();
      Logger.error(
        'ServicesController.selectService - saveConfiguration and serviceConfigurationTypeCode',
        {
          error: err,
          errorCode: timestamp
        }
      );
      GuiErrors.modalError(
        this.i18n.gettext('Non è stato possibile salvare il tipo di servizio.'),
        'Error detail: ServicesController:selectService - code: ' + timestamp
      );
    } finally {
      this.stopLoadingPage();
    }
    return configuration;
  }

  delay(ms = 1000) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async selectService(serviceCode, colorCorrection = null) {
    let nextState = null;
    let queryParams = this.request.query;

    switch (serviceCode) {
      case LayoutServicesCodes.readyToPrintCode: {
        await this.saveConfiguration(serviceCode, colorCorrection);
        this.startLoadingPage();

        nextState = 'uploader-rtp:' + this.projectType;

        EventTracker.log(
          this.customerUser,
          'design_service_type_select',
          Object.assign(productInterface(this.configuration), {
            design_service_type: LayoutServicesCodes.readyToPrintCode
          })
        );
        break;
      }

      case LayoutServicesCodes.aeVeloceCode: {
        await this.saveConfiguration(serviceCode, colorCorrection);
        this.startLoadingPage();

        let language = this.request.language;
        let editorVeloceProject = null;

        // Retry logic to temporally avoid to api/backend problems
        editorVeloceProject = await this.createEditorVeloceProject(language);

        if (editorVeloceProject.id) {
          nextState = editorVeloceProject.applicationUrl;
          queryParams = {};

          EventTracker.log(
            this.customerUser,
            'design_service_type_select',
            Object.assign(productInterface(this.configuration), {
              design_service_type: 'AEVeloce'
            })
          );
        } else {
          let timestamp = new Date().getTime();
          GuiErrors.modalError(
            this.i18n.gettext('Non è stato possibile create il progetto Editor Veloce.'),
            'Error detail: ServicesController:createEditorVeloceProject - code: ' + timestamp
          );
        }
        break;
      }

      case LayoutServicesCodes.fullServiceDesignCode: {
        await this.saveConfiguration(null, colorCorrection);
        nextState = 'design:' + this.projectType;

        EventTracker.log(
          this.customerUser,
          'design_service_type_select',
          Object.assign(productInterface(this.configuration), {
            design_service_type: LayoutServicesCodes.fullServiceDesignCode
          })
        );
        break;
      }

      default: {
        Logger.error('ServicesController.selectService - serviceNotAvailable', {
          serviceSelected: serviceCode
        });
        GuiErrors.modalError(this.i18n.gettext('Il servizio scelto non è disponibile.'));
      }
    }

    if (nextState) {
      navigateTo(this.request, this.response, nextState, {}, true, queryParams);
    }

    this.stopLoadingPage();
  }
}

export class EventBookServicesController extends BaseServicesController {
  get projectType() {
    return 'EventBook';
  }

  get funnelSteps() {
    return eventBookFunnelSteps(this.request);
  }

  get view() {
    return ServicesContentView;
  }

  async gotoPreviusStep() {
    // this.response.navigate('eventbook-packaging:main');
    navigateTo(
      this.request,
      this.response,
      'eventbook-packaging:main',
      {},
      false,
      this.request.query
    );
  }

  async initContentProps() {
    super.initContentProps();
    this.project = null;
    this.configuration = null;

    if (runtime.isClient) {
      let projectId = this.request.query.professionalProjectId;

      let configurationId = this.request.query.configurationId;

      if (projectId && configurationId) {
        this.project = new Project({ id: projectId });
        this.configuration = new EventBookConfiguration({ id: configurationId });
      } else {
        let timestamp = new Date().getTime();
        Logger.error('ServicesController.initContentProps', {
          errorCode: timestamp
        });
        GuiErrors.modalError(
          this.i18n.gettext(
            "Mancano delle parti di configurazione del prodotto. Creane uno nuovo o ripristinalo dall'area riservata"
          ),
          'Error detail: ServicesController:init - code: ' + timestamp
        );
        // this.response.navigate('eventbook:main', {});
        navigateTo(this.request, this.response, 'eventbook:new', {}, false, null);
        return false;
      }

      this.projectName = await getProjectName(this.projectType);
      if (!this.projectName) {
        this.projectName = `${this.i18n.gettext('Project')} ` + this.getDateNow();
      }
    }
  }
}

export class AlbumServicesController extends BaseServicesController {
  get projectType() {
    return 'Album';
  }

  get funnelSteps() {
    return albumFunnelSteps(this.request);
  }

  get view() {
    return ServicesContentView;
  }

  async gotoPreviusStep() {
    // this.response.navigate('album-packaging:main');
    navigateTo(this.request, this.response, 'album-packaging:main', {}, false, this.request.query);
  }

  async initContentProps() {
    super.initContentProps();
    this.project = null;
    this.configuration = null;
    this.formatType = null;

    if (runtime.isClient) {
      let projectId = this.request.query.professionalProjectId;
      let configurationId = this.request.query.configurationId;
      if (!configurationId) {
        Logger.warning(
          'AlbumServicesController.initContentProps - missing configurationId query param'
        );
        configurationId = await AlbumStorage.getConfigurationPk();
      }

      if (projectId && configurationId) {
        this.project = new Project({ id: projectId });
        this.configuration = new AlbumConfiguration({ id: configurationId });
        try {
          let getConfiguration = await this.configuration.fetch();
          this.formatType = getConfiguration.formatType;
        } catch (err) {
          let timestamp = new Date().getTime();
          Logger.error('AlbumServicesController.getConfiguration', {
            error: err,
            errorCode: timestamp,
            configuration: this.configuration
          });
          GuiErrors.modalError(
            this.i18n.gettext(
              'Non è stato possibile ricevere la configurazione, si prega di ripetere la procedura.'
            ),
            'Error detail: getConfiguration - code: ' + timestamp
          );
        }
      } else {
        let timestamp = new Date().getTime();
        Logger.error('ServicesController.initContentProps', {
          errorCode: timestamp
        });
        GuiErrors.modalError(
          this.i18n.gettext(
            "Mancano delle parti di configurazione del prodotto. Creane uno nuovo o ripristinalo dall'area riservata"
          ),
          'Error detail: ServicesController:init - code: ' + timestamp
        );
        navigateTo(this.request, this.response, 'album:new', {}, false, null);
        return false;
      }
      this.projectName = await getProjectName(this.projectType);
      if (!this.projectName) {
        this.projectName = `${this.i18n.gettext('Project')} ` + this.getDateNow();
      }
    }
  }
}

export class MattedPrintsServicesController extends BaseAEController {
  get projectType() {
    return 'MattedPrints';
  }

  get funnelSteps() {
    return mattedPrintsFunnelSteps(this.request);
  }

  get view() {
    return MattedPrintsServicesContentView;
  }

  get funnelStep() {
    return 3;
  }

  async gotoPreviusStep() {
    navigateTo(
      this.request,
      this.response,
      'matted-prints-packaging:main',
      {},
      false,
      this.request.query
    );
  }

  get context() {
    return Object.assign(super.context, {
      user: this.user,
      customerUser: this.customerUser,
      loadingPage: this.loadingPage,
      initViewRendering: this.initViewRendering
    });
  }

  async initContentProps() {
    super.initContentProps();
    this.project = null;
    this.configuration = null;
    this.formatType = null;

    if (runtime.isClient) {
      let projectId = this.request.query.professionalProjectId;
      let configurationId = this.request.query.configurationId;
      this.services = new ProjectServicesCollection(projectId);

      if (projectId && configurationId) {
        this.project = new Project({ id: projectId });
        this.configuration = new MattedPrintsConfiguration({ id: configurationId });
      } else {
        let timestamp = new Date().getTime();
        Logger.error('MattedPrintsServicesController.initContentProps', {
          error: 'projectId o configurationId mancanti',
          errorCode: timestamp
        });
        GuiErrors.modalError(
          this.i18n.gettext(
            "Mancano delle parti di configurazione del prodotto. Creane uno nuovo o ripristinalo dall'area riservata"
          ),
          'Error detail: MattedPrintsServicesController:init - code: ' + timestamp
        );
        navigateTo(this.request, this.response, 'matted-prints:new', {}, false, null);
        return false;
      }
      this.projectName = await getProjectName(this.projectType);

      if (!this.projectName) {
        //Logger.error(`Project name not available for product ${this.projectType}`);
        this.projectName = `${this.i18n.gettext('Project')} ` + this.getDateNow();
      }

      try {
        this.services = await this.services.fetch();
      } catch (err) {
        Logger.error(
          'ServicesController.initContentProps - ProjectServicesCollection fetch failed',
          {
            error: err,
            projectId: projectId
          }
        );
        GuiErrors.modalError(
          this.i18n.gettext(
            'Non è stato possibile caricare la configurazione o il progetto. Ti preghiamo di riprovare.'
          ),
          null,
          null,
          null,
          this.gotoPreviusStep.bind(this)
        );
      }
      this.render(this.context);
    }
  }

  async init() {
    this.startInitialRendering();

    await this.initContentProps();

    if (runtime.isClient) {
      try {
        await Promise.all([this.configuration.fetch(), this.project.fetch()]);
      } catch (err) {
        let timestamp = new Date().getTime();
        Logger.error(
          'MattedPrintsServicesController.init - Project or configuration fetch failed',
          {
            error: err,
            errorCode: timestamp,
            projectId: this.project.id,
            configurationId: this.configuration.id
          }
        );
        GuiErrors.modalError(
          this.i18n.gettext(
            'Non è stato possibile caricare la configurazione o il progetto. Ti preghiamo di riprovare.'
          ),
          'Error detail: ServicesController:init - code: ' + timestamp,
          null,
          null,
          this.gotoPreviusStep.bind(this)
        );
      }

      this.project.name = this.projectName;

      try {
        await this.project.save();
      } catch (err) {
        let timestamp = new Date().getTime();
        Logger.error('ServicesController.init - Project save failed', {
          error: err,
          errorCode: timestamp,
          projectId: this.project.id,
          name: this.projectName
        });
        GuiErrors.modalError(
          this.i18n.gettext('Non è stato possibile salvare il nome del progetto.'),
          'Error detail: ServicesController:init - code: ' + timestamp,
          null,
          null,
          this.gotoPreviusStep.bind(this)
        );
      }

      this.render(this.context);
      if (this.configuration.serviceConfigurationTypeCode) {
        this.redirectToCart();
        return;
      }

      this.stopInitialRendering();
    }
  }

  async createEditorVeloceProject(language = 'en') {
    let projectName = await getProjectName(this.configuration.productType);
    projectName = projectName
      ? projectName
      : `${this.i18n.gettext('Project')} ` + this.getDateNow();
    let editorVeloceProject = new MattedPrintsProject({
      shopCode: this.customerUser.shopCode,
      productConfigurationId: this.configuration.id,
      language: language,
      projectName: projectName
    });

    let maxRetryNumber = 4;
    let retryNumber = 1;
    // Retry logic to temporally avoid to api/backend problems
    do {
      try {
        editorVeloceProject = await editorVeloceProject.save();
      } catch (err) {
        Logger.error(
          `MattedPrintsServicesController.createEditorVeloceProject - createEditorVeloceProject attempt number ${retryNumber} failed.`,
          {
            shopCode: this.customerUser.shopCode,
            language: language,
            productConfigurationId: this.configuration.id,
            productConfigurationType: this.configuration.productType,
            error: err
          }
        );
        retryNumber += 1;
        await this.delay();
      }
    } while (!editorVeloceProject.id && retryNumber <= maxRetryNumber);

    return editorVeloceProject;
  }

  redirectToCart() {
    let url = `${conf.settings.CART_BASE_URL}cart/${this.project.id}`;
    navigateTo(this.request, this.response, url, {}, true);
  }

  async saveConfiguration(serviceCode, editorProjectId, colorCorrection) {
    this.startLoadingPage();

    // save service in configuration
    this.configuration.serviceConfigurationTypeCode = serviceCode;
    this.configuration.frameEditorProjectId = editorProjectId;
    this.configuration.colorCorrectionCode = colorCorrection;

    try {
      await this.configuration.save();
    } catch (err) {
      let timestamp = new Date().getTime();
      Logger.error(
        'MattedPrintsServicesController.selectService - saveConfiguration and serviceConfigurationTypeCode',
        {
          error: err,
          errorCode: timestamp
        }
      );
      GuiErrors.modalError(
        this.i18n.gettext('Non è stato possibile salvare il tipo di servizio.'),
        'Error detail: MattedPrintsServicesController:selectService - code: ' + timestamp
      );
    } finally {
      this.stopLoadingPage();
    }
  }

  delay(ms = 1000) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  async selectService(serviceCode, colorCorrection = null) {
    let nextState = null;

    this.startLoadingPage();

    let language = this.request.language;
    let editorVeloceProject = null;

    // Retry logic to temporally avoid to api/backend problems
    editorVeloceProject = await this.createEditorVeloceProject(language);

    if (editorVeloceProject.id) {
      await this.saveConfiguration(serviceCode, editorVeloceProject.id, colorCorrection);

      nextState = editorVeloceProject.applicationUrl;
    } else {
      let timestamp = new Date().getTime();
      GuiErrors.modalError(
        this.i18n.gettext('Non è stato possibile create il progetto Editor Veloce.'),
        'Error detail: MattedPrintsServicesController:createEditorVeloceProject - code: ' +
          timestamp
      );
    }

    if (nextState) {
      navigateTo(this.request, this.response, nextState, {}, true, {});
    }

    this.stopLoadingPage();
  }
}
