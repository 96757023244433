import { BaseConfiguratorController } from '../core/controllers';

import { SetShopCodeView, RemoveShopCodeView } from './views';
import { runtime } from '@outlinejs/contexts';
import Cookies from 'js-cookie';
import { conf } from 'outlinejs';
import { navigateTo } from '../core/utils';
import OAuth2 from '../auth/oauth';
import axios from 'axios';

const getUserInfo = async (token, language) => {
  try {
    const userResponse = await axios.get(`${conf.settings.USER_URL}current`, {
      headers: {
        Authorization: token,
        'x-api-key': conf.settings.X_API_KEY,
        'Accept-Language': language
      }
    });
    const { id, name, shopCode, email } = userResponse.data;
    return { id, name, shopCode, email };
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

export class SetShopCodeController extends BaseConfiguratorController {
  get view() {
    return SetShopCodeView;
  }

  get context() {
    return Object.assign(super.context, {
      initViewRendering: this.initViewRendering,
      breadcrumbBarHidden: this.breadcrumbBarHidden,
      error: this.error
    });
  }

  async initContentProps() {
    await super.initContentProps();

    this.error = undefined;
  }

  async init() {
    await this.initContentProps();

    this.breadcrumbBarHidden = true;

    this.startInitialRendering();
    if (runtime.isClient) {
      this.stopInitialRendering();
    }
  }

  async onSubmit(shopCode) {
    this.startInitialRendering();

    this.error = undefined;

    try {
      const response = await axios.post(
        conf.settings.PROFESSIONAL_USER_IMPERSONATION_URL,
        { shopCode },
        {
          headers: {
            Authorization: OAuth2.getBearerToken(),
            'x-api-key': conf.settings.X_API_KEY,
            'Accept-Language': this.request.language
          }
        }
      );
      const token = response.data.accessToken;
      const email = response.data.email;

      // set Inspectors cookie
      Cookies.set(
        conf.settings.INSPECTORS_AUTH_COOKIE_KEY,
        '{"isEnable": "true", "email": "' + email + '"}',
        {
          expires: 4 * 60 * 60,
          domain: conf.settings.AUTH_COOKIE_DOMAIN
        }
      );

      // set customer cookie
      Cookies.set(conf.settings.CUSTOMER_AUTH_COOKIE_KEY, token, {
        expires: 4 * 60 * 60,
        domain: conf.settings.AUTH_COOKIE_DOMAIN
      });

      // set professional_user_info of customer user
      const userInfo = await getUserInfo(`Bearer ${token}`, this.request.language);
      if (userInfo) {
        OAuth2.saveUserInfoToken(userInfo);
      }

      const nextUrl = this.request.query['next-url'] || conf.settings.HOMEPAGE_BASE_URL;
      navigateTo(this.request, this.response, nextUrl, {}, true);
    } catch (error) {
      switch (error.response.status) {
        case 403:
          this.error = this.i18n.gettext('You are not authorized for this operation');
          break;
        case 404:
          if (error.response.data.indexOf('ProfessionalUser not found') !== -1) {
            this.error = this.i18n.gettext('Shop code not found.');
          } else {
            this.error = this.i18n.gettext('Shop code not active');
          }
          break;
        default:
          this.error = this.i18n.gettext('Ops, something went wrong');
      }

      this.stopInitialRendering();
    }
  }
}

export class RemoveShopCodeController extends BaseConfiguratorController {
  get view() {
    return RemoveShopCodeView;
  }

  get context() {
    return Object.assign(super.context, {
      initViewRendering: this.initViewRendering
    });
  }

  async initContentProps() {
    await super.initContentProps();
  }

  async init() {
    await this.initContentProps();
    this.startInitialRendering();
    if (runtime.isClient) {
      // remove Inspectors cookie
      Cookies.set(conf.settings.INSPECTORS_AUTH_COOKIE_KEY, '{"isEnable": "true"}', {
        expires: 4 * 60 * 60,
        domain: conf.settings.AUTH_COOKIE_DOMAIN
      });

      // remove customer cookie
      Cookies.remove(conf.settings.CUSTOMER_AUTH_COOKIE_KEY, {
        domain: conf.settings.AUTH_COOKIE_DOMAIN
      });

      // remove professional_user_info cookie
      OAuth2.removeUserInfoToken();

      // recover professional_user_info of Inspector user
      const token = OAuth2.getBearerToken();
      const userInfo = await getUserInfo(token, this.request.language);
      if (userInfo) {
        OAuth2.saveUserInfoToken(userInfo);
      }

      const nextUrl = this.request.query['next-url'] || conf.settings.HOMEPAGE_BASE_URL;
      navigateTo(this.request, this.response, nextUrl, {}, true);
    }
  }
}
