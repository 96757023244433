import { routers } from 'outlinejs';
import { routing } from 'outlinejs';

import EventBookRouter from './eventbook/urls';
import AuthRouter from './auth/urls';
import EventBookPackagingRouter from './eventbook-packaging/urls';
import ServicesRouter from './services/urls';
import LayoutRouter from './layouts/urls';
import AlbumRouter from './album/urls';
import MattedPrintsRouter from './matted-prints/urls';
import MattedPrintsPackagingRouter from './matted-prints-packaging/urls';
import AlbumPackagingRouter from './album-packaging/urls';
import DuplicateRouter from './duplicate/urls';
import StylesRouter from './design-styles/urls';
import ThankyouRouter from './thankyou/urls';
import UsbDriveRouter from './usb-drive/urls';
import FramesRouter from './frames/urls';
import ConfiguratorsList from './configurators-list/urls';
import UploaderAppRouter from './uploader/urls';
import CustomerCareRouter from './customer-care/urls';

export default class extends routers.BaseRouter {
  static get urlPatterns() {
    return {
      '': routing.include(ConfiguratorsList),
      eventbook: routing.include(EventBookRouter),
      auth: routing.include(AuthRouter),
      services: routing.include(ServicesRouter),
      uploader: routing.include(UploaderAppRouter),
      design: routing.include(StylesRouter),
      'eventbook-packaging': routing.include(EventBookPackagingRouter),
      layout: routing.include(LayoutRouter),
      album: routing.include(AlbumRouter),
      'album-packaging': routing.include(AlbumPackagingRouter),
      'matted-prints': routing.include(MattedPrintsRouter),
      'matted-prints-packaging': routing.include(MattedPrintsPackagingRouter),
      'duplicate-project': routing.include(DuplicateRouter),
      'thank-you': routing.include(ThankyouRouter),
      'usb-box': routing.include(UsbDriveRouter),
      frame: routing.include(FramesRouter),
      'customer-care': routing.include(CustomerCareRouter)
    };
  }
}
