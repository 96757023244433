import { runtime } from '@outlinejs/contexts';
import { getCollection } from './utils';

import { BaseConfiguratorController } from '../core/controllers';
import Logger from '../core/logger';
import { GuiErrors, navigateTo, mergeQueryParams } from '../core/utils/index';
import { eventBookFunnelSteps } from '../core/utils/breadcrumb';
import { albumFunnelSteps } from '../core/utils/breadcrumb';
import { StylesView } from './views';
import { getEventBookConfiguration } from '../eventbook/utils';
import { getAlbumConfiguration } from '../album/utils';
import { LayoutServicesCodes } from '../services/utils';
import { DefaultBlockProject } from './models';
import { getProjectName } from '../projects/utils';

import { EventBookDesignStylesCollection, AlbumDesignStylesCollection } from './managers';

export class BaseStylesController extends BaseConfiguratorController {
  get view() {
    return StylesView;
  }

  get funnelStep() {
    return 3;
  }

  get context() {
    return Object.assign(super.context, {
      isLoading: this.isLoading,
      user: this.user,
      customerUser: this.customerUser,
      initViewRendering: this.initViewRendering,
      loadingPage: this.loadingPage,
      stylesCollection: this.stylesCollection,
      choosenStyle: this.choosenStyle,
      confirmData: this.confirmData,
      productConfiguration: this.initialConfiguration,
      configuration: this.configuration
    });
  }

  async initContentProps() {
    await super.initContentProps();

    this.stylesCollection = [];
    this.choosenStyle = false;
    this.initialConfiguration = null;
    this.configuration = null;
    this.configUpdated = false;
    this.loadingPage = false;
    this.confirmData = {
      firstName: null,
      secondName: null,
      eventLocation: null,
      eventDate: null,
      eventStyle: null
    };
  }

  async init() {
    this.startInitialRendering();

    await this.initContentProps();

    if (runtime.isClient) {
      await this.loadInitialConfiguration();
      this.reloadView();
    }
  }

  async loadInitialConfiguration() {
    try {
      this.initialConfiguration = await this.getProductConfiguration();
    } catch (error) {
      let timestamp = new Date().getTime();
      Logger.error('BaseStylesController.getConfiguration - Unable to load configuration', {
        error: error,
        errorCode: timestamp,
        user: this.user,
        customerUser: this.customerUser
      });
      GuiErrors.modalError(
        this.i18n.gettext('Qualcosa è andato storto. Ti preghiamo di riprovare'),
        'Error detail: loadConfigurations - code: ' + timestamp
      );
    }
  }

  async reloadView() {
    this.initViewRendering = true;
    this.render(this.context);
    let designStylesModels = await this.getProductDesignStyles();
    if (designStylesModels) {
      this.stylesCollection = designStylesModels.map((model) => {
        return {
          id: model.id,
          name: model.name,
          isDefault: model.isDefault,
          images: model.images,
          description: model.description,
          isNew: model.isNew
        };
      });
    }
    this.initViewRendering = false;
    this.render(this.context);
  }

  async updateConfiguration() {
    this.startLoadingPage();
    try {
      let currentConfiguration = this.getPreparedConfiguration(this.initialConfiguration);
      this.configuration = await currentConfiguration.save();
      this.configUpdated = true;
    } catch (err) {
      let timestamp = new Date().getTime();
      Logger.error('BaseStylesController.updateConfiguration - Unable to update configuration', {
        error: err,
        errorCode: timestamp,
        user: this.user,
        customerUser: this.customerUser
      });
      this.stopLoadingPage();
      return;
    }
    //Create Editor Veloce project and Redirect to upload page
    if (this.configUpdated) {
      // let nextUrl = null;
      let queryParams = {};

      if (!this.configuration.aeVeloceProjectId) {
        let language = this.request.language;

        try {
          let editorVeloceProject = await this.createEditorVeloceProject(language);
          queryParams = {
            aeVeloceProjectId: editorVeloceProject.id
          };
          if (this.projectType === 'EventBook') {
            queryParams.aeVeloceBlockId = editorVeloceProject.blockId;
          }
        } catch (err) {
          this.stopLoadingPage();
          let timestamp = new Date().getTime();
          GuiErrors.modalError(
            this.i18n.gettext('Non è stato possibile create il progetto Editor Veloce.'),
            'Error detail: BaseStylesController:createEditorVeloceProject - code: ' + timestamp
          );
          return false;
        }
      } else {
        queryParams = {
          aeVeloceProjectId: this.configuration.aeVeloceProjectId
        };
        if (this.projectType === 'EventBook') {
          queryParams.aeVeloceBlockId = this.configuration.aeVeloceBlockId;
        }
      }

      let nextState = `uploader-fsd:${this.projectType}`;

      navigateTo(
        this.request,
        this.response,
        nextState,
        {},
        false,
        mergeQueryParams(this.request.query, queryParams)
      );
      this.stopLoadingPage();
    }
  }

  async setConfirmData(data) {
    this.confirmData = data;
  }

  getPreparedConfiguration(configuration) {
    if (configuration) {
      configuration.firstName = this.confirmData.firstName;
      configuration.secondName = this.confirmData.secondName;
      configuration.eventLocation = this.confirmData.eventLocation;
      configuration.eventDate = this.confirmData.eventDate;
      configuration.eventStyle = this.confirmData.eventStyle;
      configuration.serviceConfigurationTypeCode = LayoutServicesCodes.fullServiceDesignCode;
    }
    return configuration;
  }

  async createEditorVeloceProject(language = 'en') {
    let projectName = await getProjectName(this.configuration.productType);
    let editorVeloceProject = new DefaultBlockProject({
      shopCode: this.customerUser.shopCode,
      language: language,
      productConfigurationId: this.configuration.id,
      productConfigurationType: this.configuration.productType,
      projectName: projectName
    });

    try {
      editorVeloceProject = await editorVeloceProject.save();
    } catch (err) {
      Logger.error(`BaseStylesController.createEditorVeloceProject - failed.`, {
        shopCode: this.customerUser.shopCode,
        language: language,
        productConfigurationId: this.configuration.id,
        productConfigurationType: this.configuration.productType,
        error: err
      });
    }

    return editorVeloceProject;
  }
}

export class EventBookStylesController extends BaseStylesController {
  get projectType() {
    return 'EventBook';
  }

  get funnelSteps() {
    return eventBookFunnelSteps(this.request);
  }

  async getProductConfiguration() {
    return getEventBookConfiguration(this.request.query.configurationId);
  }

  async getProductDesignStyles() {
    return getCollection(new EventBookDesignStylesCollection(), 'EventBookStylesController');
  }
}

export class AlbumStylesController extends BaseStylesController {
  get projectType() {
    return 'Album';
  }

  get funnelSteps() {
    return albumFunnelSteps(this.request);
  }

  async getProductConfiguration() {
    return getAlbumConfiguration(this.request.query.configurationId);
  }

  async getProductDesignStyles() {
    return getCollection(new AlbumDesignStylesCollection(), 'AlbumStylesController');
  }
}
