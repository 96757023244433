import React from 'react';
import PropTypes from 'prop-types';
import { AeMarkupText, AeModal } from '@photosi/albumepoca-ui';
import { components } from 'outlinejs';

import { SelectButton } from './selectButton';

import AeVeloceBigImg from '../media/images/aev_big.jpg';
import AeVeloceImg from '../media/images/aev.jpg';
import { ColorCorrectionForm } from './colorCorrectionForm';

export class AeVeloceServiceSection extends components.BaseComponent {
  constructor(props) {
    super(props);

    this.state = {
      aeveloceModal: false
    };

    this.onSelect = this.onSelect.bind(this);
    this.onShowMore = this.onShowMore.bind(this);
  }

  get showMoreTitle() {
    return this.i18n.gettext('Design by yourself');
  }

  get showMoreContent() {
    return (
      <div className="impagination__modal">
        <div className="row">
          <div className="col-sm-12">
            <div className="impagination__modal__text">
              {this.i18n.gettext(
                "Crea il tuo layout direttamente online con AE Veloce. Potrai scegliere tra l'opzione automatica e quella veloce."
              )}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 text-center">
            <div className="impagination__modal__picture">
              <img src={AeVeloceBigImg} alt={'AeVeloce'} />
              <div className="timeline__content">
                <div className="timeline__title">
                  <div className="timeline__item timeline__item--you">
                    {this.i18n.gettext('Tu')}
                  </div>
                  <div className="timeline__item timeline__item--we">
                    {this.i18n.gettext('Noi')}
                  </div>
                </div>
                <div className="timeline__pins">
                  <div className="timeline__item timeline__item--you">
                    <div className="timeline__action">
                      {this.i18n.gettext('Carica le immagini in alta risoluzione')}
                    </div>
                  </div>
                  <div className="timeline__item timeline__item--you">
                    <div className="timeline__action">
                      {this.i18n.gettext("Crea il layout usando l'opzione manuale o quella veloce")}
                    </div>
                  </div>
                  <div className="timeline__item timeline__item--you">
                    <div className="timeline__action">
                      {this.i18n.gettext("Controlla e conferma l'impaginato")}
                    </div>
                  </div>
                  <div className="timeline__item timeline__item--we">
                    <div className="timeline__action">{this.i18n.gettext('Stampa e Consegna')}</div>
                  </div>
                </div>
                <div className="timeline__arrow" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  onSelect() {
    const { selectService, code, disabled } = this.props;
    if (disabled) {
      return false;
    }
    selectService(code);
  }

  onShowMore() {
    const { showMore, disabled } = this.props;
    if (disabled) {
      return false;
    }
    showMore(this.showMoreContent, this.showMoreTitle);
  }

  render() {
    const {
      language,
      code,
      selected,
      disabled,
      serviceColorCorrections,
      colorCorrectionCode,
      handleColorCorrectionChange
    } = this.props;
    const { aeveloceModal } = this.state;

    const helpersClass = selected ? 'switchers__item active' : 'switchers__item';
    const blockClass = disabled ? 'service__blocked' : '';
    const aeVeloceTutorialPageUrl = {
      it: 'https://www.albumepoca.com/it/video-tutorial?iframe=true',
      es: 'https://www.albumepoca.com/es/tutoriales-en-video?iframe=true',
      en: 'https://www.albumepoca.com/en/tutorial-videos?iframe=true'
    };

    return (
      <div className="col-md-4">
        <div className={`${helpersClass} ${blockClass}`}>
          <div className="switchers__image">
            <img src={AeVeloceImg} style={{ opacity: 0.6 }} alt={'AeVeloce'} />
          </div>
          <a
            className="tutorial-btn"
            onClick={() => {
              this.setState({ aeveloceModal: true });
            }}>
            <i className="fa fa-play-circle-o fa-5x" />
            <span>{this.i18n.gettext('show tutorial')}</span>
          </a>
          <AeModal
            isOpen={aeveloceModal}
            onClose={() => {
              this.setState({ aeveloceModal: false });
            }}
            text={
              '<iframe class="hubspot-page" src="' +
              aeVeloceTutorialPageUrl[language] +
              '" width="100%" allowFullScreen="" frameborder="0" />'
            }
            customClasses={{ 'text-center': true, 'modal-fullscreen': true }}
          />
          <div className="switchers__text">
            <div className="fix_min_height text-left">
              <p>
                <strong className={'text-uppercase'}>
                  <AeMarkupText text={this.i18n.gettext('Impagini online  \r con AE Veloce')} />
                </strong>
                <AeMarkupText
                  text={this.i18n.gettext(
                    'Carichi le immagini in alta risoluzione e   \r crei la tua impaginazione online con il programma AE Veloce'
                  )}
                />
              </p>
            </div>
            <div className="color_correction">
              <div className="divider" />
              {serviceColorCorrections && serviceColorCorrections.length && (
                <ColorCorrectionForm
                  serviceColorCorrections={serviceColorCorrections}
                  colorCorrectionCode={colorCorrectionCode}
                  inputId={'aeVeloceColorCorrection'}
                  handleColorCorrectionChange={handleColorCorrectionChange}
                />
              )}
            </div>
          </div>
          <div className="switchers__actions">
            <SelectButton
              isSelected={selected}
              handleSelect={this.onSelect}
              btnText={this.i18n.gettext('Scegli')}
              action={`set_service_${code}`}
            />
          </div>
        </div>
        <a onClick={this.onShowMore}>{this.i18n.gettext('Approfondisci')}</a>
        {disabled && (
          <div className="alert alert-info">
            {this.i18n.gettext('Il servizio non è disponibile per il formato scelto')}
          </div>
        )}
      </div>
    );
  }
}

AeVeloceServiceSection.propTypes = {
  language: PropTypes.string,
  code: PropTypes.string,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  selectService: PropTypes.func,
  showMore: PropTypes.func,
  colorCorrectionCode: PropTypes.string,
  handleColorCorrectionChange: PropTypes.func,
  serviceColorCorrections: PropTypes.array
};
