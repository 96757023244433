import Logger from '../core/logger';

export async function getCollection(manager, type) {
  let modelsCollection = null;
  try {
    let collection = manager;
    modelsCollection = await collection.fetch();
  } catch (err) {
    Logger.error(`${type}: getCollection`, {
      error: err
    });
  }
  return modelsCollection;
}

export async function delay(ms = 1000) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
