//import {BaseCollection} from '../core/managers';
import { LoadableCollection } from '../core/managers';
import { conf } from 'outlinejs';

import { DesignStyle } from './models';

export class EventBookDesignStylesCollection extends LoadableCollection {
  get name() {
    return 'designStyles';
  }

  get url() {
    return conf.settings.EVENT_BOOK_DESIGN_STYLES_URL;
  }

  get model() {
    return DesignStyle;
  }
}

export class AlbumDesignStylesCollection extends LoadableCollection {
  get name() {
    return 'designStyles';
  }

  get url() {
    return conf.settings.ALBUM_DESIGN_STYLES_URL;
  }

  get model() {
    return DesignStyle;
  }
}
