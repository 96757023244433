export class LayoutServicesCodes {
  static get readyToPrintCode() {
    return 'ReadyToPrint';
  }

  static get aeVeloceCode() {
    return 'EditorResponsive';
  }

  static get aeStudioCode() {
    return 'AEStudio';
  }

  static get fullServiceDesignCode() {
    return 'FullServiceDesign';
  }
}
