import { conf } from 'outlinejs';

export default class extends conf.BaseSettings {
  get MIDDLEWARE() {
    return [
      require('./auth/middleware').default,
      require('./core/middleware').default,
      require('./album/middleware').default
    ];
  }

  get DEFAULT_LANGUAGE() {
    return 'en';
  }

  get LANGUAGES() {
    return ['en', 'it', 'es'];
  }

  get ENABLED_SHOPCODES() {
    return [
      '00010031260', //test.it.albumepoca@gmail.com
      '00010031314', //test.es.albumepoca@gmail.com
      '00010031315', //test.ca.albumepoca@gmail.com
      '00010031320', //test.uk.albumepoca@gmail.com
      '00010031318', //test.ie.albumepoca@gmail.com
      // '00010031319', //test.si.albumepoca@gmail.com
      '00010032820', //test.de.albumepoca@gmail.com
      // '00010031313', //test.e2.albumepoca@gmail.com
      // '00010031317', //test.in.albumepoca@gmail.com
      '00010031171' //test.us.albumepoca@gmail.com
      // '00010031312', //test.ww.albumepoca@gmail.com
    ];
  }

  get METALLIC_COLORS() {
    return {
      STEEL: 'linear-gradient(-45deg, #939292, #e2e1e0, #939292)',
      PINK_GOLD: 'linear-gradient(-45deg, #f7a06f, #f9c6a9, #f7a06f)',
      STEEL_SHADOW: 'url(#innershadow)',
      PINK_GOLD_SHADOW: ''
    };
  }

  get FRAME_CATALOG() {
    return {
      it: 'catalogo-quadri-cornici',
      es: 'catálogo-cuadros-marcos-de-mesa',
      en: 'catalogue-wall-tabletop-frames'
    };
  }

  get PROMO_URL() {
    return {
      it: 'https://www.albumepoca.com/it/ae_promozioni_iframe',
      es: 'https://www.albumepoca.com/es/ae_promozioni_iframe',
      en: 'https://www.albumepoca.com/en/ae_promozioni_iframe'
    };
  }

  get DEVELOP_FRAME_URLS() {
    return {
      'Quadri e Cornici':
        'frame/new/?category=WALL&frameMaterial=WOOD&format=20x20&orientation=SQUARE&paper=VELVET&frameColor=WHITE&softwareCode=PROFESSIONAL&commercialProduct=FRAME&photoSupport=NO&sideThickness=NO&spacer=NO&supportType=NO&paperLamination=NO&language=it',
      'Acrilic Satin Wall':
        'frame/new/?category=WALL&frameMaterial=ACRYLIC_SATIN&format=28x35&orientation=VERTICAL&paper=VELVET&frameColor=BLACK&softwareCode=PROFESSIONAL&commercialProduct=ACRYLIC_SATIN&photoSupport=NO&sideThickness=NO&spacer=NO&supportType=NO&paperLamination=NO&language=it',
      'Corten Wall':
        'frame/new/?category=WALL&frameMaterial=CORTEN&format=28x35&orientation=VERTICAL&paper=VELVET&frameColor=RUST&softwareCode=PROFESSIONAL&commercialProduct=CORTEN&photoSupport=NO&sideThickness=NO&spacer=NO&supportType=NO&paperLamination=NO&language=it',
      'Wood Slats Wall':
        'frame/new/?category=WALL&frameMaterial=WOOD_SLATS&format=75x50&orientation=HORIZONTAL&paper=VELVET&frameColor=WHITE&softwareCode=PROFESSIONAL&commercialProduct=WOOD_SLATS&photoSupport=NO&sideThickness=NO&spacer=NO&supportType=NO&paperLamination=NO&language=it',
      'Wood Baby Wall':
        'frame/new/?category=WALL&frameMaterial=WOOD_BABY&format=40x30&orientation=HORIZONTAL&paper=VELVET&frameColor=NATURAL_WHITE_GREEN_BOTTLE&softwareCode=PROFESSIONAL&commercialProduct=WOOD_BABY&photoSupport=NO&sideThickness=NO&spacer=NO&supportType=NO&paperLamination=NO&language=it',
      'Acrilic Satin Tabletop':
        'frame/new/?category=TABLETOP&frameMaterial=ACRYLIC_SATIN&format=26x20&orientation=HORIZONTAL&paper=VELVET&frameColor=BLACK&softwareCode=PROFESSIONAL&commercialProduct=ACRYLIC_SATIN&photoSupport=NO&sideThickness=NO&spacer=NO&supportType=NO&paperLamination=NO&language=it',
      'Corten Tabletop':
        'frame/new/?category=TABLETOP&frameMaterial=CORTEN&format=20x20&orientation=SQUARE&paper=METAL&frameColor=RUST&softwareCode=PROFESSIONAL&commercialProduct=CORTEN&photoSupport=NO&sideThickness=NO&spacer=NO&supportType=NO&paperLamination=ACRYLIC&language=it',
      'Wood Slats Tabletop':
        'frame/new/?category=TABLETOP&frameMaterial=WOOD&format=25x25&orientation=SQUARE&paper=METAL&frameColor=WHITE&softwareCode=PROFESSIONAL&commercialProduct=WOOD&photoSupport=NO&sideThickness=NO&spacer=NO&supportType=NO&paperLamination=ACRYLIC&language=it',
      'Wood Baby Tabletop':
        'frame/new/?category=TABLETOP&frameMaterial=WOOD_BABY&format=30x25&orientation=HORIZONTAL&paper=VELVET&frameColor=NATURAL_WHITE_PLUM&softwareCode=PROFESSIONAL&commercialProduct=WOOD_BABY&photoSupport=NO&sideThickness=NO&spacer=NO&supportType=NO&paperLamination=NO&language=it',
      Canvas:
        'frame/new/?category=WALL&frameMaterial=NO&format=70x50&orientation=HORIZONTAL&paper=CANVAS&frameColor=NO&softwareCode=PROFESSIONAL&commercialProduct=CANVAS&photoSupport=RODS&sideThickness=4_CM&spacer=NO&supportType=NO&paperLamination=NO&language=it',
      'Piuma/Dibond':
        'frame/new/?category=WALL&frameMaterial=NO&format=40x30&orientation=HORIZONTAL&paper=PHOTOGRAPHIC_MATTE_FUJI&frameColor=BLACK&softwareCode=PROFESSIONAL&commercialProduct=PANEL&photoSupport=FEATHER&sideThickness=1_CM&spacer=NO&supportType=NO&paperLamination=MATTE&language=it'
    };
  }

  get METALLIC_FILTERS() {
    return {
      STEEL:
        '<defs><linearGradient id="metallic-vertical" x1="50%" y1="0%" x2="50%" y2="100%"><stop offset="0%" stop-color="#686868"></stop><stop offset="50%" stop-color="#ffffff"></stop><stop offset="100%" stop-color="#686868"></stop></linearGradient><linearGradient id="metallic-horizontal" x1="0%" y1="50%" x2="100%" y2="50%" ><stop offset="0%" stop-color="#686868"></stop><stop offset="50%" stop-color="#d8d8cd"></stop><stop offset="100%" stop-color="#686868"></stop></linearGradient><filter id="innershadow" x0="-50%" y0="-50%" width="200%" height="200%"><feGaussianBlur in="SourceAlpha" stdDeviation="3" result="blur"></feGaussianBlur><feOffset dy="2" dx="3"></feOffset><feComposite in2="SourceAlpha" operator="arithmetic" k2="-1" k3="1" result="shadowDiff"></feComposite><feFlood flood-color="#444444" flood-opacity="0.75"></feFlood><feComposite in2="shadowDiff" operator="in"></feComposite><feComposite in2="SourceGraphic" operator="over" result="firstfilter"></feComposite><feGaussianBlur in="firstfilter" stdDeviation="3" result="blur2"></feGaussianBlur><feOffset dy="-2" dx="-3"></feOffset><feComposite in2="firstfilter" operator="arithmetic" k2="-1" k3="1" result="shadowDiff"></feComposite><feFlood flood-color="#444444" flood-opacity="0.75"></feFlood><feComposite in2="shadowDiff" operator="in"></feComposite><feComposite in2="firstfilter" operator="over"></feComposite></filter></defs>',
      PINK_GOLD:
        '<defs><linearGradient id="metallic-vertical" x1="50%" y1="10%" x2="60%" y2="60%"><stop offset="0%" stop-color="#a55436"></stop><stop offset="50%" stop-color="#c98d6a"></stop><stop offset="100%" stop-color="#a55436"></stop></linearGradient><linearGradient id="metallic-horizontal" x1="0%" y1="50%" x2="100%" y2="50%"><stop offset="0%" stop-color="#686868"></stop><stop offset="50%" stop-color="#d8d8cd"></stop><stop offset="100%" stop-color="#686868"></stop></linearGradient></defs>'
    };
  }

  get IS_PRODUCTION_ENV() {
    return process.env.NODE_ENV === 'production';
  }

  get GTM_ID() {
    return process.env.OJS_GTM_ID;
  }

  get DIDOMI_API_KEY() {
    return process.env.OJS_DIDOMI_API_KEY;
  }

  get DIDOMI_CONSENT_ID() {
    return process.env.OJS_DIDOMI_CONSENT_ID;
  }

  get X_API_KEY() {
    return process.env.OJS_X_API_KEY;
  }

  get USER_AGENT() {
    return 'Web/1.0.0 (Software/WEB; +http://corporate.photosi.com/)';
  }

  get HOMEPAGE_BASE_URL() {
    return process.env.OJS_HOMEPAGE_BASE_URL;
  }

  get AE_REGISTRATION_IT_URL() {
    return process.env.OJS_AE_REGISTRATION_IT_URL;
  }

  get AE_REGISTRATION_EN_URL() {
    return process.env.OJS_AE_REGISTRATION_EN_URL;
  }

  get AE_REGISTRATION_ES_URL() {
    return process.env.OJS_AE_REGISTRATION_ES_URL;
  }

  get POW_WEBSITE_BASE_URL() {
    return process.env.OJS_POW_WEBSITE_BASE_URL;
  }

  get CART_BASE_URL() {
    return process.env.OJS_CART_BASE_URL;
  }

  get SHOPPING_CART_PATH() {
    return 'shopping/cart/backend/';
  }

  get REGISTER_PATH() {
    return 'pif-auth/registration/';
  }

  get NO_PROFESSIONAL_PATH() {
    return 'no-professional/';
  }

  get MEMBERSHIP_BASE_URL() {
    return 'http://boost.albumepoca.com/';
  }

  get MYALBUMEPOCA_BASE_URL() {
    return process.env.OJS_MYALBUMEPOCA_BASE_URL;
  }

  get MYALBUMEPOCA_ORDERS_URL() {
    return `${this.MYALBUMEPOCA_BASE_URL}#/main/orders/professional`;
  }

  get RESET_FILTERS_COOKIE_KEY() {
    return process.env.OJS_RESET_FILTERS_COOKIE_KEY;
  }

  get RESET_FILTERS_COOKIE_DOMAIN() {
    return process.env.OJS_RESET_FILTERS_COOKIE_DOMAIN;
  }

  get UNIT_OF_MEASURE_COOKIE_KEY() {
    return process.env.OJS_UNIT_OF_MEASURE_COOKIE_KEY;
  }

  get UNIT_OF_MEASURE_COOKIE_DOMAIN() {
    return process.env.OJS_UNIT_OF_MEASURE_COOKIE_DOMAIN;
  }

  get DEFAULT_UNIT_OF_MEASURE() {
    return 'cm';
  }

  get MATTED_PRINTS_STAND_ACCESSORY_TYPE() {
    return 'MattedPrintsStand';
  }

  get EVENT_BOOK_PRODUCT_TYPE() {
    return 'EventBook';
  }

  get EVENT_BOOK_PACKAGING_PRODUCT_TYPE() {
    return 'EventBookPackaging';
  }

  get ALBUM_PRODUCT_TYPE() {
    return 'Album';
  }

  get ALBUM_PACKAGING_PRODUCT_TYPE() {
    return 'AlbumPackaging';
  }

  get MATTED_PRINTS_PRODUCT_TYPE() {
    return 'MattedPrints';
  }

  get MATTED_PRINTS_PACKAGING_PRODUCT_TYPE() {
    return 'MattedPrintsPackaging';
  }

  get USB_DRIVE_PRODUCT_TYPE() {
    return 'UsbDrive';
  }

  get USB_DRIVE_PACKAGING_PRODUCT_TYPE() {
    return 'UsbDrivePackaging';
  }

  get FRAME_PRODUCT_TYPE() {
    return 'HomeDecor';
  }

  get FRAME_PACKAGING_PRODUCT_TYPE() {
    return 'Packaging';
  }

  /**------------------------------ LOGGER SETTINGS --------------------------------------------**/

  get SENTRY_DSN() {
    return process.env.OJS_SENTRY_DSN;
  }

  get AMPLITUDE_API_KEY() {
    return process.env.OJS_AMPLITUDE_API_KEY;
  }

  get AMPLITUDE_CONSENT_ID() {
    return 'c:amplitude';
  }

  /**------------------------------ OAUTH2 and USER SETTINGS --------------------------------------------**/

  get USER_URL() {
    return `${this.API_BASE_URL}accounts/professional-users/`;
  }

  get USER_BASE_ADDRESS_URL() {
    return `${this.API_BASE_URL}accounts/professional-users/`;
  }

  get OAUTH2_LOGOUT_URL() {
    return process.env.OJS_OAUTH2_LOGOUT_URL;
  }

  get OAUTH2_CLIENT_ID() {
    return process.env.OJS_OAUTH2_CLIENT_ID;
  }

  get OAUTH2_CLIENT_SECRET() {
    return process.env.OJS_OAUTH2_CLIENT_SECRET;
  }

  get OAUTH2_TOKEN_URL() {
    return process.env.OJS_OAUTH2_TOKEN_URL;
  }

  get OAUTH2_AUTHORIZE_URL() {
    return process.env.OJS_OAUTH2_AUTHORIZE_URL;
  }

  get API_BASE_URL() {
    return process.env.OJS_API_BASE_URL;
  }

  get API_VERSION() {
    return process.env.OJS_API_VERSION || '0.0.1';
  }

  get AUTH_COOKIE_DOMAIN() {
    return process.env.OJS_AUTH_COOKIE_DOMAIN;
  }

  get AUTH_COOKIE_KEY() {
    return process.env.OJS_AUTH_COOKIE_KEY;
  }

  get USER_INFO_COOKIE_KEY() {
    return process.env.OJS_USER_INFO_COOKIE_KEY;
  }

  get CUSTOMER_AUTH_COOKIE_KEY() {
    return `customer_${this.AUTH_COOKIE_KEY}`;
  }

  get INSPECTORS_AUTH_COOKIE_KEY() {
    return 'Inspectors';
  }

  /**------------------------------ PUM/UP SETTINGS --------------------------------------------**/

  get PUM_URL() {
    return process.env.OJS_PUM_URL;
  }

  get PUM_SOCKET_URL() {
    return `${this.PUM_URL}/uploader/api/upload-manager`;
  }

  get EVENTBOOK_PUM_BLOCKPAGE_CONFIGURATION() {
    return process.env.OJS_EVENTBOOK_PUM_BLOCKPAGE_CONFIGURATION;
  }

  get EVENTBOOK_PUM_FSD_BLOCKPAGE_CONFIGURATION() {
    return process.env.OJS_EVENTBOOK_PUM_FSD_BLOCKPAGE_CONFIGURATION;
  }

  get ALBUM_PUM_BLOCKPAGE_CONFIGURATION() {
    return process.env.OJS_ALBUM_PUM_BLOCKPAGE_CONFIGURATION;
  }

  get ALBUM_PUM_FSD_BLOCKPAGE_CONFIGURATION() {
    return process.env.OJS_ALBUM_PUM_FSD_BLOCKPAGE_CONFIGURATION;
  }

  get DOWNLOAD_COLOR_PROFILE_URL() {
    return {
      it: 'https://storage.googleapis.com/areariservata/documents/Profili_colore_Album_Epoca_2020.zip',
      es: 'https://storage.googleapis.com/areariservata/documents/Perfil_de_color_Album_Epoca_2020.zip',
      en: 'https://storage.googleapis.com/areariservata/documents/Color-Profile-ENG_2020.zip'
    };
  }

  get DOWNLOAD_TEMPLATE_URL() {
    return {
      it: 'https://storage.googleapis.com/epoca/downloads/AE%20Guida%20Crop/IT/guida_posizionamento_immagini_IT.pdf',
      es: 'https://storage.googleapis.com/areariservata/documents/guida_posizionamento_immagini_ES.pdf',
      en: 'https://storage.googleapis.com/areariservata/documents/Crop_Area_Album_Epoca.pdf'
    };
  }

  get DOWNLOAD_RTP_TEMPLATE_URL() {
    return {
      it: 'https://storage.googleapis.com/areariservata/documents/Ready_to_Print_Templates.zip',
      es: 'https://storage.googleapis.com/areariservata/documents/Ready_to_Print_Templates.zip',
      en: 'https://storage.googleapis.com/areariservata/documents/Ready_to_Print_Templates.zip'
    };
  }

  /**------------------------------ EVENT BOOK SETTINGS --------------------------------------------**/

  get EVENT_BOOK_MATERIAL_INITIAL_CONFIGURATION() {
    return {
      bindingType: 'FLEX',
      eventType: null,
      sheetsNumber: 5,
      paperType: 'PHOTO',
      paperLaminationType: null,
      orientationType: 'SQUARE',
      formatType: '20x20',
      coverType: 'MATERIAL',
      coverLaminationType: 'OPACA',
      coverColorMaterialType: 'COVER_TIZIANO_NATURALE',
      coverPaddingType: 'NO',
      coverUvGraphicType: 'UV_NO',
      coverUvGraphicThemeType: 'WEDDING',
      coverUvGraphicElementType: null,
      coverUvTexts: {},
      debossingTexts: {},
      flyleafType: 'FLYLEAF_STANDARD',
      flyleafColorMaterialType: 'FLYLEAF_BLACK',
      plaqueType: 'PLAQUE_STANDARD',
      plaqueTexts: {},
      previewBookCode: 'EPOCA_EVENT_BOOK_FLEX_20x20_COVER_FRONT_TIZIANO_NATURALE_101',
      pagesNumber: 10,
      shopCode: null
    };
  }

  get EVENT_BOOK_MATERIAL_VELVET_INITIAL_CONFIGURATION() {
    return {
      bindingType: 'HOT_BINDING',
      eventType: null,
      sheetsNumber: 5,
      paperType: 'VELVET',
      paperLaminationType: null,
      orientationType: 'SQUARE',
      formatType: '20x20',
      coverType: 'MATERIAL',
      coverLaminationType: 'OPACA',
      coverColorMaterialType: 'COVER_TIZIANO_NATURALE',
      coverPaddingType: 'NO',
      coverUvGraphicType: 'UV_NO',
      coverUvGraphicThemeType: 'WEDDING',
      coverUvGraphicElementType: null,
      coverUvTexts: {},
      debossingTexts: {},
      flyleafType: 'FLYLEAF_STANDARD',
      flyleafColorMaterialType: 'FLYLEAF_BLACK',
      plaqueType: 'PLAQUE_STANDARD',
      plaqueTexts: {},
      previewBookCode: 'EPOCA_EVENT_BOOK_HOT_BINDING_20x20_COVER_FRONT_TIZIANO_NATURALE_101',
      pagesNumber: 10,
      shopCode: null
    };
  }

  get EVENT_BOOK_PHOTOGRAPHIC_INITIAL_CONFIGURATION() {
    return {
      bindingType: 'FLEX',
      eventType: null,
      sheetsNumber: 5,
      paperType: 'PHOTO',
      paperLaminationType: null,
      orientationType: 'SQUARE',
      formatType: '20x20',
      coverType: 'PHOTOGRAPHIC',
      coverLaminationType: 'OPACA',
      coverColorMaterialType: null,
      coverPaddingType: 'NO',
      coverUvGraphicType: 'UV_NO',
      coverUvGraphicThemeType: 'WEDDING',
      coverUvGraphicElementType: null,
      coverUvTexts: {},
      debossingTexts: {},
      flyleafType: 'FLYLEAF_STANDARD',
      flyleafColorMaterialType: 'FLYLEAF_BLACK',
      plaqueType: 'PLAQUE_STANDARD',
      plaqueTexts: {},
      previewBookCode: 'EPOCA_EVENT_BOOK_FLEX_20x20_COVER_FRONT_PHOTOGRAPHIC_OPACA',
      pagesNumber: 10,
      shopCode: null
    };
  }

  get EVENT_BOOK_PHOTOGRAPHIC_CART_PREVIEW_SVG_FILE_TYPE() {
    return 'FrontCoverPreviewImage';
  }

  get EVENT_BOOK_PHOTOGRAPHIC_PLAYER_3D_SVG_FILE_TYPE() {
    return 'FullCoverPlain3DPreviewImage';
  }

  get EVENT_BOOK_BLOCK_PRODUCTION_INFO_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/block-production-infos/`;
  }

  get EVENT_BOOK_PRICE_VALIDITY_WIDGET_LIST() {
    // if there's an error non this filters, the price will be not valid
    // for example you cant get an eventbook with 4000 pages price, because is not available
    return ['sheetsNumber'];
  }

  get EVENT_BOOK_PRODUCTS_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/products/`;
  }

  get EVENT_BOOK_CONFIGURATIONS_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/configurations/`;
  }

  get EVENT_BOOK_PRICES_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/prices/`;
  }

  get EVENT_BOOK_COVER_PRODUCTION_INFO_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/cover-production-infos/`;
  }

  get EVENT_BOOK_COVER_BINDING_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/lookups/bindings/`;
  }

  get EVENT_BOOK_EVENT_TYPE_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/lookups/events/`;
  }

  get EVENT_BOOK_PAPER_FINISHING_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/lookups/papers/`;
  }

  get EVENT_BOOK_DECORATION_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/lookups/cover-decorations/`;
  }

  get EVENT_BOOK_DEBOSSING_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/lookups/debossing/`;
  }

  get EVENT_BOOK_DEBOSSING_ELEMENT_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/lookups/debossing-elements/`;
  }

  get EVENT_BOOK_DEBOSSING_ELEMENT_COLOR_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/lookups/debossing-colors/`;
  }

  get EVENT_BOOK_DEBOSSING_TEXTS_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/lookups/debossing-texts/`;
  }

  get EVENT_BOOK_PAGE_LAMINATION_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/lookups/page-laminations/`;
  }

  get EVENT_BOOK_ORIENTATION_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/lookups/orientations/`;
  }

  get EVENT_BOOK_FORMAT_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/lookups/formats/`;
  }

  get EVENT_BOOK_COVER_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/lookups/covers/`;
  }

  get EVENT_BOOK_COVER_LAMINATION_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/lookups/cover-laminations/`;
  }

  get EVENT_BOOK_COVER_COLORS_MATERIALS_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/lookups/cover-colors-materials/`;
  }

  get EVENT_BOOK_COVER_MATERIALS_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/lookups/cover-materials/`;
  }

  get EVENT_BOOK_COVER_COLORS_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/lookups/cover-colors/`;
  }

  get EVENT_BOOK_COVER_PADDING_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/lookups/cover-padding/`;
  }

  get EVENT_BOOK_COVER_UV_GRAPHIC_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/lookups/cover-uv-graphics/`;
  }

  get EVENT_BOOK_COVER_UV_GRAPHIC_THEME_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/lookups/cover-uv-graphic-themes/`;
  }

  get EVENT_BOOK_COVER_UV_GRAPHIC_ELEMENT_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/lookups/cover-uv-graphic-elements/`;
  }

  get EVENT_BOOK_COVER_UV_GRAPHIC_TEXT_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/lookups/cover-uv-graphic-texts/`;
  }

  get EVENT_BOOK_PLAQUE_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/lookups/plaques/`;
  }

  get EVENT_BOOK_PLAQUE_TEXT_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/lookups/plaques-texts/`;
  }

  get EVENT_BOOK_COVER_FLYLEAF_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/lookups/flyleafs/`;
  }

  get EVENT_BOOK_COVER_FLYLEAF_COLORS_MATERIALS_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/lookups/flyleafs-colors-materials/`;
  }

  get EVENT_BOOK_DESIGN_STYLES_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/lookups/styles/`;
  }

  get EVENT_BOOK_DECORATION_DEBOSSING_TYPE() {
    return 'DEBOSSING_FOIL';
  }

  /**------------------------------ EVENT BOOK PACKAGING  SETTINGS --------------------------------------------**/

  get EVENT_BOOK_PACKAGING_INITIAL_CONFIGURATION() {
    return {
      packagingType: 'MATERIAL',
      formatType: '20x20',
      packagingColorMaterialType: 'TIZIANO_NATURALE',
      packagingDecorationType: 'NONE',
      packagingUvGraphicType: 'NO',
      packagingUvGraphicThemeType: 'WEDDING',
      packagingUvGraphicElementType: null,
      packagingDebossingType: 'DEBOSSING_NO',
      packagingDebossingElementType: null,
      packagingDebossingElementFormatType: null,
      packagingDebossingElementColorType: null,
      packagingUvTexts: {},
      packagingDebossingTexts: {}
    };
  }

  get EVENT_BOOK_PACKAGING_DECORATION_DEBOSSING_TYPE() {
    return 'DEBOSSING_FOIL';
  }

  get EVENT_BOOK_PACKAGING_PRODUCTS_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book-packaging/products/`;
  }

  get EVENT_BOOK_PACKAGING_CONFIGURATIONS_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book-packaging/configurations/`;
  }

  get EVENT_BOOK_PACKAGING_PRICES_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book-packaging/prices/`;
  }

  get EVENT_BOOK_PACKAGING_TYPES_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book-packaging/lookups/packagings/`;
  }

  get EVENT_BOOK_PACKAGING_COLORS_MATERIALS_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book-packaging/lookups/packaging-colors-materials/`;
  }

  get EVENT_BOOK_PACKAGING_MATERIALS_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book-packaging/lookups/packaging-materials/`;
  }

  get EVENT_BOOK_PACKAGING_COLORS_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book-packaging/lookups/packaging-colors/`;
  }

  get EVENT_BOOK_PACKAGING_UV_GRAPHIC_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book-packaging/lookups/packaging-uv-graphics/`;
  }

  get EVENT_BOOK_PACKAGING_UV_GRAPHIC_THEME_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book-packaging/lookups/packaging-uv-graphic-themes/`;
  }

  get EVENT_BOOK_PACKAGING_UV_GRAPHIC_ELEMENT_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book-packaging/lookups/packaging-uv-graphic-elements/`;
  }

  get EVENT_BOOK_PACKAGING_UV_GRAPHIC_TEXT_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book-packaging/lookups/packaging-uv-graphic-texts/`;
  }

  get EVENT_BOOK_PACKAGING_DECORATION_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book-packaging/lookups/packaging-decorations/`;
  }

  get EVENT_BOOK_PACKAGING_DEBOSSING_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book-packaging/lookups/packaging-debossing/`;
  }

  get EVENT_BOOK_PACKAGING_DEBOSSING_ELEMENT_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book-packaging/lookups/packaging-debossing-elements/`;
  }

  get EVENT_BOOK_PACKAGING_DEBOSSING_ELEMENT_COLOR_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book-packaging/lookups/packaging-debossing-colors/`;
  }

  get EVENT_BOOK_PACKAGING_DEBOSSING_TEXTS_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book-packaging/lookups/packaging-debossing-texts/`;
  }

  /**------------------------------ ORDERS AND PRE ORDERS  SETTINGS --------------------------------------------**/

  get PROFESSIONAL_PRE_ORDERS_URL() {
    return `${this.API_BASE_URL}pre-orders/professional/pre-orders/`;
  }

  get CREATE_COVER_EDITOR_PROJECT_URL() {
    return `${this.API_BASE_URL}editor-veloce/projects/`;
  }

  /**------------------------------ COVER EDITOR SETTINGS --------------------------------------------**/

  get COVER_EDITOR_PROJECT_URL() {
    return `${this.API_BASE_URL}editor-veloce/cover-photographic-projects/`;
  }

  get COVER_URL() {
    return `${this.API_BASE_URL}editor-veloce/covers-photographic/`;
  }

  get COVER_EDITOR_APPLICATION_URL() {
    return process.env.OJS_COVER_EDITOR_APPLICATION_URL;
  }

  get COVER_3D_PLAYER_APPLICATION_URL() {
    return process.env.OJS_COVER_3D_PLAYER_APPLICATION_URL;
  }

  get PHOTOGRAPHIC_BOOK_COVER_DEFAULT_CONFIGURATIONS() {
    return [
      {
        id: 'VERTICAL',
        cardBoardHeight: 785,
        cardBoardWidth: 549,
        channelWidth: 39,
        spineWidth: 56,
        padding: 28,
        borderThickness: 8,
        paperType: 'COVER_DECORATION_LAMINATION_GLOSSY',
        urlSvg: 'https://pum.test.photosi.com/media/project/1/cover/cover.svg',
        urlRasterizedImage:
          'https://file.photosi.com/aerepo/copertine/event_book/image/Fotografico_V.jpg'
      },
      {
        id: 'SQUARE',
        cardBoardHeight: 728,
        cardBoardWidth: 688,
        channelWidth: 39,
        spineWidth: 56,
        padding: 28,
        borderThickness: 8,
        paperType: 'COVER_DECORATION_LAMINATION_GLOSSY',
        urlSvg: 'https://pum.test.photosi.com/media/project/1/cover/cover.svg',
        urlRasterizedImage:
          'https://file.photosi.com/aerepo/copertine/event_book/image/Fotografico_Q.jpg'
      },
      {
        id: 'HORIZONTAL',
        cardBoardHeight: 586,
        cardBoardWidth: 748,
        channelWidth: 39,
        spineWidth: 56,
        padding: 28,
        borderThickness: 8,
        paperType: 'COVER_DECORATION_LAMINATION_GLOSSY',
        urlSvg: 'https://pum.test.photosi.com/media/project/1/cover/cover.svg',
        urlRasterizedImage:
          'https://file.photosi.com/aerepo/copertine/event_book/image/Fotografico_O.jpg'
      }
    ];
  }

  get PHOTOGRAPHIC_BOOK_COVER_PUM_CONFIGURATION() {
    return process.env.OJS_PHOTOGRAPHIC_BOOK_COVER_PUM_CONFIGURATION;
  }

  get RASTERIZED_IMAGE_UPLOAD_URL() {
    return `${this.PUM_URL}/uploader/api/upload-lite/${this.PHOTOGRAPHIC_BOOK_COVER_PUM_CONFIGURATION}/`;
  }

  get RASTERIZED_IMAGE_FOLDER() {
    return 'cover';
  }

  get RASTERIZED_IMAGE_FILENAME() {
    return 'cover_preview.png';
  }

  get PUM_URL_BASE_MEDIA() {
    return `${this.PUM_URL}/media/project`;
  }

  get EDITOR_BASE_APPLICATION_URL() {
    return process.env.OJS_EDITOR_BASE_APPLICATION_URL;
  }

  get PROFESSIONAL_PROJECTS_URL() {
    return `${this.API_BASE_URL}projects/professional/projects/`;
  }

  get EVENT_BOOK_PROFESSIONAL_FILES_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/configurations/`;
  }

  get EVENT_BOOK_CONFIGURATION_FILES_URL() {
    return `${this.API_BASE_URL}configurators/professional/event-book/files/`;
  }

  get ALBUM_PROFESSIONAL_FILES_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/configurations/`;
  }

  get ALBUM_CONFIGURATION_FILES_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/files/`;
  }

  get EDITOR_VELOCE_PROJECTS_URL() {
    return `${this.API_BASE_URL}editor-veloce/projects/`;
  }

  get EDITOR_VELOCE_DEFAULT_BLOCK_PROJECTS_URL() {
    return `${this.API_BASE_URL}editor-veloce/default-block-projects/`;
  }

  get EDITOR_VELOCE_MATTED_PRINTS_PROJECTS_URL() {
    return `${this.API_BASE_URL}editor-veloce/matted-prints-projects/`;
  }

  get EDITOR_VELOCE_PROFESSIONAL_URL() {
    return `${this.EDITOR_BASE_APPLICATION_URL}professional`;
  }

  get EDITOR_VELOCE_EVENTBOOK_URL() {
    return `${this.EDITOR_BASE_APPLICATION_URL}professional-eventbook`;
  }

  get EDITOR_VELOCE_ALBUM_URL() {
    return `${this.EDITOR_BASE_APPLICATION_URL}professional-album`;
  }

  get EDITOR_VELOCE_MATTED_PRINTS_URL() {
    return `${this.EDITOR_BASE_APPLICATION_URL}matted-prints`;
  }

  get EDITOR_VELOCE_HOMEDECOR_URL() {
    return `${this.EDITOR_BASE_APPLICATION_URL}home-decor-frame`;
  }

  get PROFESSIONAL_PROJECTS_DESIGN_SERVICES_URL() {
    return `${this.API_BASE_URL}projects/professional/projects/design-services`;
  }

  /**------------------------------ ALBUM SECTION --------------------------------------------**/

  get ALBUM_COVER_UV_GRAPHIC_TYPE_NO() {
    return 'NO';
  }

  //TODO: é solo un placeholder
  get ALBUM_INITIAL_CONFIGURATION() {
    return {
      aeVeloceProjectId: null,
      aeVeloceBlockId: null,
      coverEditorProjectId: 2224,
      bandType: 'NO',
      bandTexts: {},
      bindingType: 'HOT_BINDING',
      eventType: null,
      configurationFamily: 'BELLAGIO',
      coverColorMaterialType: 'ECOLEATHER_RED',
      coverEngravedType: 'NO',
      coverEngravedTexts: {},
      debossingType: 'NO',
      debossingTexts: {},
      dryDebossingType: 'NO',
      flyleafColorMaterialType: 'CARDBOARD_BLACK',
      flyleafType: 'FLYLEAF_STANDARD',
      formatType: '35x45',
      gildingType: 'NO',
      initialType: 'NO',
      initialTexts: {},
      pagesNumber: 20,
      orientationType: 'VERTICAL',
      paperLaminationType: 'NO',
      paperType: 'OPACA',
      plaqueType: 'NO',
      plaqueTexts: {},
      plateType: 'STANDARD',
      plateTexts: {},
      previewImageUrl: 'aerepo/copertine/album/BELLAGIO-2-CONT-R1.JPG',
      previewStatus: 'LOADED',
      productName: 'Album',
      productType: 'Album',
      serviceConfigurationTypeCode: null,
      serviceConfigurationName: null,
      shopCode: '00010031171',
      swarovskiType: 'NO'
    };
  }

  get ALBUM_BLOCK_PRODUCTION_INFO_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/block-info/`;
  }

  get ALBUM_CONFIGURATIONS_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/configurations/`;
  }

  get ALBUM_PRODUCTS_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/products/`;
  }

  get ALBUM_PRICES_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/prices/`;
  }

  get ALBUM_PROFESSIONAL_FILES_TO_UPDATE_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/files/`;
  }

  get ALBUM_BINDING_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/bindings/`;
  }

  get ALBUM_EVENT_TYPE_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/events/`;
  }

  get ALBUM_PAPER_FINISHING_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/papers/`;
  }

  get ALBUM_MIXED_PAPERS_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/mixed-papers/`;
  }

  get ALBUM_PAGE_LAMINATION_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/page-laminations/`;
  }

  get ALBUM_ORIENTATION_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/orientations/`;
  }

  get ALBUM_FORMAT_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/formats/`;
  }

  get ALBUM_COVER_COLORS_MATERIALS_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/cover-color-materials/`;
  }

  get ALBUM_COVER_UV_GRAPHIC_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/cover-uv-graphics/`;
  }

  get ALBUM_COVER_UV_GRAPHIC_THEME_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/cover-uv-graphic-themes/`;
  }

  get ALBUM_COVER_UV_GRAPHIC_ELEMENT_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/cover-uv-graphic-elements/`;
  }

  get ALBUM_COVER_UV_GRAPHIC_TEXT_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/cover-uv-graphic-texts/`;
  }

  get ALBUM_COVER_FLYLEAF_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/flyleafs/`;
  }

  get ALBUM_COVER_FLYLEAF_COLORS_MATERIALS_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/flyleafs-colors-materials/`;
  }

  get ALBUM_PLAQUE_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/plaques/`;
  }

  get ALBUM_PLAQUE_TEXT_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/plaques-texts/`;
  }

  get ALBUM_INITIALS_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/initials/`;
  }

  get ALBUM_INITIALS_TEXT_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/initials-texts/`;
  }

  get ALBUM_BANDS_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/bands/`;
  }

  get ALBUM_BANDS_TEXT_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/bands-texts/`;
  }

  get ALBUM_DEBOSSINGS_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/debossings/`;
  }

  get ALBUM_DEBOSSING_TEXT_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/debossing-texts/`;
  }

  get ALBUM_COVER_ENGRAVEDS_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/cover-engraveds/`;
  }

  get ALBUM_COVER_ENGRAVEDS_TEXT_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/cover-engraved-texts/`;
  }

  get ALBUM_DRY_DEBOSSINGS_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/dry-debossings/`;
  }

  get ALBUM_SWAROVSKY_CRYSTAL_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/swarovski-crystal/`;
  }

  get ALBUM_GILDINGS_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/gildings/`;
  }

  get ALBUM_ENGRAVED_NAMES_METAL_PLAQUE_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/engraved-names-metal_plaque/`;
  }

  get ALBUM_ENGRAVED_NAMES_METAL_PLAQUE_TEXTS_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/engraved-names-metal_plaque-texts/`;
  }

  get ALBUM_PLAQUES_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/plaques/`;
  }

  get ALBUM_PLAQUES_TEXTS_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/plaques-texts/`;
  }

  get ALBUM_PLATE_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/plate/`;
  }

  get ALBUM_PLATE_TEXTS_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/plate-texts/`;
  }

  get ALBUM_COVER_PHOTO_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/cover-photo/`;
  }

  get ALBUM_DESIGN_STYLES_URL() {
    return `${this.API_BASE_URL}configurators/professional/album/lookups/styles/`;
  }

  /**------------------------------ COVER WITH PHOTO EDITOR SETTINGS --------------------------------------------**/

  get COVER_WITH_PHOTO_EDITOR_PROJECT_URL() {
    return `${this.API_BASE_URL}editor-veloce/cover-with-photo-projects/`;
  }

  get ALBUM_COVER_EDITOR_APPLICATION_URL() {
    return process.env.OJS_ALBUM_COVER_EDITOR_APPLICATION_URL;
  }

  /**------------------------------ BOX PHOTO EDITOR SETTINGS --------------------------------------------**/

  get BOX_WITH_PHOTOS_EDITOR_PROJECT_URL() {
    return `${this.API_BASE_URL}editor-veloce/box-with-photos-projects/`;
  }

  get BOX_WITH_PHOTOS_EDITOR_APPLICATION_URL() {
    return process.env.OJS_BOX_WITH_PHOTOS_EDITOR_APPLICATION_URL;
  }

  /**------------------------------ ALBUM PACKAGING  SETTINGS --------------------------------------------**/

  get ALBUM_PACKAGING_INITIAL_CONFIGURATION() {
    return {
      shopCode: null,
      packagingType: 'STANDARD'
      // packagingUvGraphicThemeType: 'WEDDING',
      // packagingUvGraphicElementType: 'VARIANTS_101',
      // packagingUvGraphicColorType: 'SILVER',
      // packagingUvTexts: null
    };
  }

  get ALBUM_PACKAGING_PRODUCTS_URL() {
    return `${this.API_BASE_URL}configurators/professional/album-packaging/products/`;
  }

  get ALBUM_PACKAGING_CONFIGURATIONS_URL() {
    return `${this.API_BASE_URL}configurators/professional/album-packaging/configurations/`;
  }

  get ALBUM_PACKAGING_PRICES_URL() {
    return `${this.API_BASE_URL}configurators/professional/album-packaging/prices/`;
  }

  get ALBUM_PACKAGING_TYPES_URL() {
    return `${this.API_BASE_URL}configurators/professional/album-packaging/lookups/packagings/`;
  }

  get ALBUM_PACKAGING_BAG_COLORS_URL() {
    return `${this.API_BASE_URL}configurators/professional/album-packaging/lookups/bag-colors/`;
  }

  get ALBUM_PACKAGING_UV_GRAPHIC_INHERITED_URL() {
    return `${this.API_BASE_URL}configurators/professional/album-packaging/lookups/packaging-uv-graphic-inherited-types/`;
  }

  get ALBUM_PACKAGING_UV_GRAPHIC_INHERITED_INFOS_URL() {
    return `${this.API_BASE_URL}configurators/professional/album-packaging/lookups/packaging-uv-graphic-inherited-infos/`;
  }

  get ALBUM_PACKAGING_UV_GRAPHIC_COLLECTIONS_URL() {
    return `${this.API_BASE_URL}configurators/professional/album-packaging/lookups/packaging-uv-graphic-collections/`;
  }

  get ALBUM_PACKAGING_UV_GRAPHIC_TYPE_URL() {
    return `${this.API_BASE_URL}configurators/professional/album-packaging/lookups/packaging-uv-graphic-types/`;
  }

  get ALBUM_PACKAGING_UV_GRAPHIC_THEME_URL() {
    return `${this.API_BASE_URL}configurators/professional/album-packaging/lookups/packaging-uv-graphic-themes/`;
  }

  get ALBUM_PACKAGING_UV_GRAPHIC_ELEMENT_URL() {
    return `${this.API_BASE_URL}configurators/professional/album-packaging/lookups/packaging-uv-graphic-elements/`;
  }

  get ALBUM_PACKAGING_UV_GRAPHIC_TEXT_URL() {
    return `${this.API_BASE_URL}configurators/professional/album-packaging/lookups/packaging-uv-graphic-texts/`;
  }

  get ALBUM_PACKAGING_COVER_PHOTO_URL() {
    return `${this.API_BASE_URL}configurators/professional/album-packaging/lookups/packaging-cover-photo/`;
  }

  get ALBUM_PACKAGING_UV_GRAPHIC_COLORS_URL() {
    return `${this.API_BASE_URL}configurators/professional/album-packaging/lookups/packaging-uv-graphic-colors/`;
  }

  /**------------------------------ MATTED PRINTS SECTION --------------------------------------------**/

  get MATTED_PRINTS_INITIAL_CONFIGURATION() {
    return {
      eventType: null,
      orientationType: 'HORIZONTAL',
      formatType: '20x15',
      quantity: 5,
      paperType: 'OPACA',
      paperLaminationType: null,
      frameColorMaterialType: 'STANDARD_WHITE',
      shopCode: null
    };
  }

  get MATTED_PRINTS_CONFIGURATIONS_URL() {
    return `${this.API_BASE_URL}configurators/professional/matted-prints/configurations`;
  }

  get MATTED_PRINTS_PRODUCTS_URL() {
    return `${this.API_BASE_URL}configurators/professional/matted-prints/products`;
  }

  get MATTED_PRINTS_PRICES_URL() {
    return `${this.API_BASE_URL}configurators/professional/matted-prints/prices`;
  }

  get MATTED_PRINTS_PROFESSIONAL_FILES_TO_UPDATE_URL() {
    return `${this.API_BASE_URL}configurators/professional/matted-prints/files`;
  }

  get MATTED_PRINTS_ORIENTATION_URL() {
    return `${this.API_BASE_URL}configurators/professional/matted-prints/lookups/orientations`;
  }

  get MATTED_PRINTS_FORMAT_URL() {
    return `${this.API_BASE_URL}configurators/professional/matted-prints/lookups/formats`;
  }

  get MATTED_PRINTS_EVENT_TYPE_URL() {
    return `${this.API_BASE_URL}configurators/professional/matted-prints/lookups/events`;
  }

  get MATTED_PRINTS_PAPER_URL() {
    return `${this.API_BASE_URL}configurators/professional/matted-prints/lookups/papers`;
  }

  get MATTED_PRINTS_PAGE_LAMINATION_URL() {
    return `${this.API_BASE_URL}configurators/professional/matted-prints/lookups/paper-laminations`;
  }

  get MATTED_PRINTS_FRAME_COLOR_MATERIAL_URL() {
    return `${this.API_BASE_URL}configurators/professional/matted-prints/lookups/frame-color-materials`;
  }

  /**------------------------------ MATTED PRINTS STAND SECTION --------------------------------------------**/

  get MATTED_PRINTS_STAND_NO() {
    return 'CONFIG_MATTED_PRINTS_STAND_NO';
  }

  get MATTED_PRINTS_STAND_CONFIGURATIONS_URL() {
    return `${this.API_BASE_URL}configurators/professional/matted-print-stand/configurations`;
  }

  get MATTED_PRINTS_STANDS_URL() {
    return `${this.API_BASE_URL}configurators/professional/matted-print-stand/lookups/stands`;
  }

  /**------------------------------ MATTED PRINTS PACKAGING SECTION --------------------------------------------**/

  get MATTED_PRINTS_PACKAGING_INITIAL_CONFIGURATION() {
    return {
      shopCode: null,
      packagingType: 'DELUXE',
      formatType: '20x20',
      colorMaterialType: 'BLACK',
      ribbonColor: 'SILVER',
      uvPhotographerName: 'NO',
      uvGraphicType: 'NO'
    };
  }

  get MATTED_PRINTS_PACKAGING_PRODUCTS_URL() {
    return `${this.API_BASE_URL}configurators/professional/matted-prints-packaging/products`;
  }

  get MATTED_PRINTS_PACKAGING_CONFIGURATIONS_URL() {
    return `${this.API_BASE_URL}configurators/professional/matted-prints-packaging/configurations`;
  }

  get MATTED_PRINTS_PACKAGING_PRICES_URL() {
    return `${this.API_BASE_URL}configurators/professional/matted-prints-packaging/prices`;
  }

  get MATTED_PRINTS_PACKAGING_TYPES_URL() {
    return `${this.API_BASE_URL}configurators/professional/matted-prints-packaging/lookups/packagings`;
  }

  get MATTED_PRINTS_PACKAGING_COLOR_MATERIALS_URL() {
    return `${this.API_BASE_URL}configurators/professional/matted-prints-packaging/lookups/packaging-color-materials`;
  }

  get MATTED_PRINTS_PACKAGING_RIBBON_COLORS_URL() {
    return `${this.API_BASE_URL}configurators/professional/matted-prints-packaging/lookups/ribbon-colors`;
  }

  get MATTED_PRINTS_PACKAGING_UV_GRAPHIC_COLLECTIONS_URL() {
    return `${this.API_BASE_URL}configurators/professional/matted-prints-packaging/lookups/packaging-uv-graphic-types`;
  }

  get MATTED_PRINTS_PACKAGING_UV_GRAPHIC_THEMES_URL() {
    return `${this.API_BASE_URL}configurators/professional/matted-prints-packaging/lookups/packaging-uv-graphic-themes`;
  }

  get MATTED_PRINTS_PACKAGING_UV_GRAPHIC_ELEMENTS_URL() {
    return `${this.API_BASE_URL}configurators/professional/matted-prints-packaging/lookups/packaging-uv-graphic-elements`;
  }

  get MATTED_PRINTS_PACKAGING_UV_GRAPHIC_TEXTS_URL() {
    return `${this.API_BASE_URL}configurators/professional/matted-prints-packaging/lookups/packaging-uv-graphic-texts`;
  }

  get MATTED_PRINTS_PACKAGING_UV_PHOTOGRAPHER_NAMES_URL() {
    return `${this.API_BASE_URL}configurators/professional/matted-prints-packaging/lookups/uv-photographer-names`;
  }

  get MATTED_PRINTS_PACKAGING_UV_PHOTOGRAPHER_NAME_TEXTS_URL() {
    return `${this.API_BASE_URL}configurators/professional/matted-prints-packaging/lookups/uv-photographer-name-texts`;
  }

  /**------------------------------ DUPLICATION SECTION --------------------------------------------**/

  get DUPLICATE_ALBUM_DATA() {
    return {
      code: 'ALBUM_DEFAULT'
    };
  }

  get DUPLICATE_EVENTBOOK_MATERIAL_DATA() {
    return {
      code: 'EVENTBOOK_MATERIAL'
    };
  }

  get DUPLICATE_EVENTBOOK_PHOTOGRAPHIC_DATA() {
    return {
      code: 'EVENTBOOK_PHOTOGRAPHIC'
    };
  }

  get DUPLICATE_MATTED_PRINTS_DATA() {
    return {
      code: 'MATTEDPRINTS_DEFAULT'
    };
  }

  /**------------------------------ USB DRIVE SECTION --------------------------------------------**/

  get USB_DRIVE_INITIAL_CONFIGURATION() {
    return {
      capacityType: 'USB_CAPACITY_16GB'
    };
  }

  get USB_DRIVE_CONFIGURATIONS_URL() {
    return `${this.API_BASE_URL}configurators/professional/usb-drive/configurations`;
  }

  get USB_DRIVE_EVENT_TYPE_URL() {
    return `${this.API_BASE_URL}configurators/professional/usb-drive/lookups/events`;
  }

  get USB_DRIVE_CAPACITY_URL() {
    return `${this.API_BASE_URL}configurators/professional/usb-drive/lookups/capacities`;
  }

  /**------------------------------ USB DRIVE PACKAGING SECTION --------------------------------------------**/

  get USB_DRIVE_PACKAGING_CONFIGURATIONS_URL() {
    return `${this.API_BASE_URL}configurators/professional/usb-drive-packaging/configurations`;
  }

  get USB_DRIVE_PACKAGING_PRODUCTS_URL() {
    return `${this.API_BASE_URL}configurators/professional/usb-drive-packaging/products`;
  }

  get USB_DRIVE_PACKAGING_PRICES_URL() {
    return `${this.API_BASE_URL}configurators/professional/usb-drive-packaging/prices`;
  }

  get USB_DRIVE_PACKAGING_PROFESSIONAL_FILES_TO_UPDATE_URL() {
    return `${this.API_BASE_URL}configurators/professional/usb-drive-packaging/files`;
  }

  get USB_DRIVE_PACKAGING_COLOR_MATERIALS_URL() {
    return `${this.API_BASE_URL}configurators/professional/usb-drive-packaging/lookups/packaging-color-materials`;
  }

  get USB_DRIVE_PACKAGING_UV_GRAPHIC_COLLECTIONS_URL() {
    return `${this.API_BASE_URL}configurators/professional/usb-drive-packaging/lookups/packaging-uv-graphic-types`;
  }

  get USB_DRIVE_PACKAGING_UV_GRAPHIC_THEMES_URL() {
    return `${this.API_BASE_URL}configurators/professional/usb-drive-packaging/lookups/packaging-uv-graphic-themes`;
  }

  get USB_DRIVE_PACKAGING_UV_GRAPHIC_ELEMENTS_URL() {
    return `${this.API_BASE_URL}configurators/professional/usb-drive-packaging/lookups/packaging-uv-graphic-elements`;
  }

  get USB_DRIVE_PACKAGING_UV_GRAPHIC_TEXTS_URL() {
    return `${this.API_BASE_URL}configurators/professional/usb-drive-packaging/lookups/packaging-uv-graphic-texts`;
  }

  get USB_DRIVE_PACKAGING_UV_PHOTOGRAPHER_NAMES_URL() {
    return `${this.API_BASE_URL}configurators/professional/usb-drive-packaging/lookups/uv-photographer-names`;
  }

  get USB_DRIVE_PACKAGING_UV_PHOTOGRAPHER_NAME_TEXTS_URL() {
    return `${this.API_BASE_URL}configurators/professional/usb-drive-packaging/lookups/uv-photographer-name-texts`;
  }

  get USB_DRIVE_PACKAGING_UV_GRAPHIC_ELEMENT_COLOR_URL() {
    return `${this.API_BASE_URL}configurators/professional/usb-drive-packaging/lookups/uv-graphic-element-colors`;
  }

  get EDITOR_VELOCE_IMAGES_URL() {
    return `${this.API_BASE_URL}editor-veloce/images/`;
  }

  get DEFAULT_BLOCK_IMAGES_URL() {
    return `${this.API_BASE_URL}editor-veloce/default-block-projects/`;
  }

  /**------------------------------ FRAME SECTION --------------------------------------------**/

  get PROFESSIONAL_SOFTWARE_CODE() {
    return 'PROFESSIONAL';
  }

  get FRAME_PRODUCTS_URL() {
    return `${this.API_BASE_URL}configurators/consumer/homedecors/products`;
  }

  get FRAME_PRICES_URL() {
    return `${this.API_BASE_URL}configurators/consumer/homedecors/prices`;
  }

  get FRAME_CONFIGURATIONS_URL() {
    return `${this.API_BASE_URL}configurators/consumer/homedecors/configurations`;
  }

  get FRAME_ORIENTATIONS_URL() {
    return `${this.API_BASE_URL}configurators/consumer/homedecors/lookups/orientations`;
  }

  get FRAME_FORMATS_URL() {
    return `${this.API_BASE_URL}configurators/consumer/homedecors/lookups/formats`;
  }

  get FRAME_COLORS_URL() {
    return `${this.API_BASE_URL}configurators/consumer/homedecors/lookups/frame-colors`;
  }

  get FRAME_PAPERS_URL() {
    return `${this.API_BASE_URL}configurators/consumer/homedecors/lookups/papers`;
  }

  get FRAME_SPACER_TYPES_URL() {
    return `${this.API_BASE_URL}configurators/consumer/homedecors/lookups/spacers`;
  }

  get FRAME_PHOTO_SUPPORT_TYPES_URL() {
    return `${this.API_BASE_URL}configurators/consumer/homedecors/lookups/photo-supports`;
  }

  get FRAME_PAPER_LAMINATION_TYPES_URL() {
    return `${this.API_BASE_URL}configurators/consumer/homedecors/lookups/paper-laminations`;
  }

  get FRAME_SUPPORT_TYPES_URL() {
    return `${this.API_BASE_URL}configurators/consumer/homedecors/lookups/support-types`;
  }

  get FRAME_SIDE_THICKNESS_TYPES_URL() {
    return `${this.API_BASE_URL}configurators/consumer/homedecors/lookups/side-thicknesses`;
  }

  get HOME_DECOR_USER_CONFIGURATION_FILE_URL() {
    return `${this.API_BASE_URL}configurators/consumer/homedecors/files`;
  }

  get FRAMES_DEFAULT_CONFIGURATION() {
    return {
      category: 'TABLETOP',
      format: '20x20',
      orientation: 'SQUARE',
      paper: 'VELVET',
      frameColor: 'BLACK',
      frameMaterial: 'ACRYLIC_SATIN',
      commercialProduct: 'ACRYLIC_SATIN',
      softwareCode: 'PROFESSIONAL'
    };
  }

  get PROFESSIONAL_USER_IMPERSONATION_URL() {
    return `${this.API_BASE_URL}professional/impersonation/users`;
  }

  get PROJECTS_QUANTITY_URL() {
    return `${this.API_BASE_URL}projects/professional/projects/`;
  }

  get OUT_OF_STOCK_ALBUM_COVER_MATERIALS() {
    return [];
  }

  get OUT_OF_STOCK_ALBUM_PACKAGING_TYPES() {
    return ['WOOD'];
  }

  get OUT_OF_STOCK_ALBUM_PACKAGING_FORMATS() {
    return [];
  }

  get OUT_OF_STOCK_ALBUM_PACKAGING_MESSAGE() {
    return {
      it: 'A causa di un ritardo sulla consegna, la Prestige Box nei formati 30x30, 30x40 e 40x30 è momentaneamente esaurita. Ci scusiamo del disagio e prevediamo di riprenderne regolarmente le consegne a partire da fine settembre.',
      es: 'Debido a un retraso en la entrega, la Prestige Box en formatos 30x30, 30x40 y 40x30 está temporalmente agotada. Pedimos disculpas por las molestias y esperamos reanudar las entregas regulares a partir de finales de septiembre.',
      en: 'Due to a delay in delivery, the Prestige Box in the sizes 12x12in (30x30cm), 16x12in (40x30cm) and 12x16in (30x40cm) is temporarily out of stock. We apologize for the inconvenience and expect to resume regular deliveries from the end of September.'
    };
  }
}
