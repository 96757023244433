import { BaseModel } from '../core/models';
import { conf } from 'outlinejs';

export class User extends BaseModel {
  get urlRoot() {
    return conf.settings.USER_URL;
  }

  get id() {
    return this.get('id');
  }

  get name() {
    return this.get('name');
  }

  get email() {
    return this.get('email');
  }

  get isActive() {
    return this.get('isActive');
  }

  get shopCode() {
    return this.get('shopCode');
  }

  get paymentType() {
    return this.get('paymentType');
  }

  get billingAddressId() {
    return this.get('billingAddressId');
  }

  get shippingAddressId() {
    return this.get('shippingAddressId');
  }

  get token() {
    return this.get('token');
  }

  set token(value) {
    this.set('token', value);
  }

  get membershipInfo() {
    return this.get('membershipInfo');
  }

  get countryIsoName() {
    return this.get('countryIsoName');
  }

  static hasActiveMembership(userObject) {
    return userObject && userObject.membershipInfo && userObject.membershipInfo.isActive;
  }
}
