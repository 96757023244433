import { BaseCollection } from '../core/managers';
import { conf } from 'outlinejs';

import { DuplicateProject, DuplicateCompatibleProduct } from './models';
import { mergeBackboneOptions } from '../core/models';

export class DuplicateProjectsCollection extends BaseCollection {
  constructor(options) {
    super();
    this.projectId = options.projectId;
  }

  get url() {
    return `${conf.settings.PROFESSIONAL_PROJECTS_URL}${this.projectId}/`;
  }

  get model() {
    return DuplicateProject;
  }

  fetch(options = {}) {
    let params = {
      data: { softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE, includeValidation: true }
    };
    options = mergeBackboneOptions(options, params);
    return super.fetch(options);
  }
}

export class DuplicateCompatibleProductsCollection extends BaseCollection {
  constructor(options) {
    super();
    this.projectId = options.projectId;
  }

  get url() {
    return `${conf.settings.PROFESSIONAL_PROJECTS_URL}${this.projectId}/compatible-products`;
  }

  get model() {
    return DuplicateCompatibleProduct;
  }

  fetch(options = {}) {
    let params = { data: { softwareCode: conf.settings.PROFESSIONAL_SOFTWARE_CODE } };
    options = mergeBackboneOptions(options, params);
    return super.fetch(options);
  }
}
