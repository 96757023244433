import { routers } from 'outlinejs';
import { routing } from 'outlinejs';

import {
  EventBookServicesController,
  AlbumServicesController,
  MattedPrintsServicesController
} from './controllers';

export default class extends routers.BaseRouter {
  static get urlPatterns() {
    return {
      eventbook: routing.url('services:EventBook', EventBookServicesController),
      album: routing.url('services:Album', AlbumServicesController),
      mattedprints: routing.url('services:MattedPrints', MattedPrintsServicesController)
    };
  }
}
