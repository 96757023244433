import { conf } from 'outlinejs';
import { runtime } from '@outlinejs/contexts';

import Logger from '../core/logger';
import { GuiErrors, navigateTo } from '../core/utils/index';
import { BaseConfiguratorController } from '../core/controllers';
import { BaseLoadingView } from '../core/views';
import { getEventBookConfiguration } from '../eventbook/utils';
import { getAlbumConfiguration } from '../album/utils';
import { getMattedPrintsConfiguration } from '../matted-prints/utils';
import { LayoutServicesCodes } from '../services/utils';

export class BaseLayoutDispatchController extends BaseConfiguratorController {
  get view() {
    return BaseLoadingView;
  }

  get context() {
    return Object.assign(super.context, {
      initViewRendering: this.initViewRendering
    });
  }

  async init() {
    this.startInitialRendering();

    if (runtime.isClient) {
      let [configuration] = await this.getConfigurations();
      //check if configuration are with valid shop code
      if (configuration) {
        if (configuration.shopCode) {
          if (
            configuration.serviceConfigurationTypeCode.toUpperCase() ===
            LayoutServicesCodes.readyToPrintCode.toUpperCase()
          ) {
            // ------------------------------
            // READY TO PRINT ---------------
            // ------------------------------
            const nextState = `uploader-rtp:${this.projectType}`;
            navigateTo(this.request, this.response, nextState, {}, true, this.request.query);
          } else if (
            configuration.serviceConfigurationTypeCode.toUpperCase() ===
            LayoutServicesCodes.aeVeloceCode.toUpperCase()
          ) {
            // ------------------------------
            // AE VELOCE --------------------
            // ------------------------------
            let aeProjectId = configuration.aeVeloceProjectId || configuration.frameEditorProjectId;
            if (aeProjectId) {
              navigateTo(
                this.request,
                this.response,
                this.getAeVeloceApplicationUrl(aeProjectId),
                {},
                true
              );
            } else {
              GuiErrors.modalError(
                this.i18n.gettext('Non esiste un progetto associato a questo prodotto.')
              );
            }
          } else if (
            configuration.serviceConfigurationTypeCode.toUpperCase() ===
            LayoutServicesCodes.fullServiceDesignCode.toUpperCase()
          ) {
            // ------------------------------
            // FULL SERVICE DESIGN ----------
            // ------------------------------
            if (
              configuration.fullServiceDesignRequested &&
              configuration.fullServiceDesignCompleted
            ) {
              navigateTo(
                this.request,
                this.response,
                this.getAeVeloceApplicationUrl(configuration.aeVeloceProjectId),
                {},
                true,
                this.request.query
              );
            } else if (
              configuration.fullServiceDesignRequested &&
              !configuration.fullServiceDesignCompleted
            ) {
              navigateTo(this.request, this.response, conf.settings.CART_BASE_URL, {}, true);
            } else {
              navigateTo(
                this.request,
                this.response,
                `design:${this.projectType}`,
                {},
                true,
                this.request.query
              );
            }
          } else {
            GuiErrors.modalError(this.i18n.gettext('Choose a service for this product.'));
            navigateTo(
              this.request,
              this.response,
              `services:${this.projectType}`,
              {},
              true,
              this.request.query
            );
          }
        } else {
          GuiErrors.modalError(
            this.i18n.gettext('Questa configurazione non ha uno shop code associato.')
          );
        }
      }
    }
  }
}

export class EventBookLayoutDispatchController extends BaseLayoutDispatchController {
  get projectType() {
    return 'EventBook';
  }

  getAeVeloceApplicationUrl(projectId) {
    return `${conf.settings.EDITOR_VELOCE_EVENTBOOK_URL}?projectId=${projectId}`;
  }

  async getConfigurations() {
    let configurationId = this.request.query.configurationId;

    return await Promise.all([getEventBookConfiguration(configurationId)]).then(
      (configurations) => {
        return configurations;
      },
      (error) => {
        let timestamp = new Date().getTime();
        Logger.error(
          'EventBookLayoutDispatchController.getConfigurations - Unable to load configurations',
          {
            error: error,
            errorCode: timestamp,
            user: this.user,
            customerUser: this.customerUser
          }
        );
        GuiErrors.modalError(
          this.i18n.gettext('Qualcosa è andato storto. Ti preghiamo di riprovare'),
          'Error detail: loadConfigurations - code: ' + timestamp
        );
        return [null, null];
      }
    );
  }
}

export class AlbumLayoutDispatchController extends BaseLayoutDispatchController {
  get projectType() {
    return 'Album';
  }

  getAeVeloceApplicationUrl(projectId) {
    return `${conf.settings.EDITOR_VELOCE_ALBUM_URL}?projectId=${projectId}`;
  }

  async getConfigurations() {
    let configurationId = this.request.query.configurationId;

    return await Promise.all([getAlbumConfiguration(configurationId)]).then(
      (configurations) => {
        return configurations;
      },
      (error) => {
        let timestamp = new Date().getTime();
        Logger.error(
          'AlbumLayoutDispatchController.getConfigurations - Unable to load configurations',
          {
            error: error,
            errorCode: timestamp,
            user: this.user,
            customerUser: this.customerUser
          }
        );
        GuiErrors.modalError(
          this.i18n.gettext('Qualcosa è andato storto. Ti preghiamo di riprovare'),
          'Error detail: loadConfigurations - code: ' + timestamp
        );
        return [null, null];
      }
    );
  }
}

export class MattedPrintsLayoutDispatchController extends BaseLayoutDispatchController {
  get projectType() {
    return 'MattedPrints';
  }

  getAeVeloceApplicationUrl(projectId) {
    return `${conf.settings.EDITOR_VELOCE_MATTED_PRINTS_URL}?projectId=${projectId}`;
  }

  async getConfigurations() {
    let configurationId = this.request.query.configurationId;
    let configuration = null;
    try {
      configuration = await getMattedPrintsConfiguration(configurationId);
    } catch (err) {
      let timestamp = new Date().getTime();
      Logger.error(
        'MattedPrintsLayoutDispatchController.getConfigurations - Unable to load configurations',
        {
          error: err,
          errorCode: timestamp,
          user: this.user,
          customerUser: this.customerUser
        }
      );
      GuiErrors.modalError(
        this.i18n.gettext('Qualcosa è andato storto. Ti preghiamo di riprovare'),
        'Error detail: loadConfigurations - code: ' + timestamp
      );
      return null;
    }
    return [configuration];
  }
}
