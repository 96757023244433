import { BaseConfiguratorController } from '../core/controllers';
import { ConfiguratorsListView } from './views';
import { runtime } from '@outlinejs/contexts';

export class ConfiguratorsListController extends BaseConfiguratorController {
  static get loginRequired() {
    return false;
  }

  get view() {
    return ConfiguratorsListView;
  }

  get context() {
    return Object.assign(super.context, {
      initViewRendering: this.initViewRendering,
      breadcrumbBarHidden: this.breadcrumbBarHidden
    });
  }

  async initContentProps() {
    await super.initContentProps();
    this.breadcrumbBarHidden = true;
  }

  async init() {
    await this.initContentProps();
    this.startInitialRendering();
    if (runtime.isClient) {
      this.stopInitialRendering();
    }
  }
}
