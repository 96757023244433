import { LoadableCollection } from '../core/managers';
import { conf } from 'outlinejs';
import { ProjectServices } from './models';

export class ProjectServicesCollection extends LoadableCollection {
  constructor(projectId) {
    super();
    this.projectId = projectId;
  }

  get url() {
    return `${conf.settings.PROFESSIONAL_PROJECTS_DESIGN_SERVICES_URL}?projectId=${this.projectId}`;
  }
  get name() {
    return 'projectServicesCollection';
  }

  get model() {
    return ProjectServices;
  }
}
