import { routers } from 'outlinejs';
import { routing } from 'outlinejs';

import { ConfiguratorsListController } from './controllers';

export default class extends routers.BaseRouter {
  static get urlPatterns() {
    return {
      '': routing.url('configurators-list:main', ConfiguratorsListController)
    };
  }
}
