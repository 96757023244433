import { conf } from 'outlinejs';
import { runtime } from '@outlinejs/contexts';
import { controllers } from 'outlinejs';

import Logger from '../core/logger';
import { DuplicateView, DuplicateProductsView } from './views';
import { DuplicateProjectsCollection, DuplicateCompatibleProductsCollection } from './managers';
import axios from 'axios';
import OAuth2 from '../auth/oauth';
import * as poller from 'backbone-poller';
import { GuiErrors, navigateTo } from '../core/utils/index';
import { BaseConfiguratorController } from '../core/controllers';

export class DuplicateProductsController extends BaseConfiguratorController {
  get view() {
    return DuplicateProductsView;
  }

  get context() {
    return Object.assign(super.context, {
      initViewRendering: this.initViewRendering,
      breadcrumbBarHidden: this.breadcrumbBarHidden,
      compatibleProducts: this.compatibleProducts,
      projectId: this.projectId
    });
  }

  async initContentProps() {
    await super.initContentProps();
    this.breadcrumbBarHidden = true;
    this.compatibleProductsCollection = new DuplicateCompatibleProductsCollection({
      projectId: this.request.query.projectId
    });
    this.projectId = this.request.query.projectId;
  }

  async init() {
    await this.initContentProps();
    this.startInitialRendering();
    if (runtime.isClient) {
      try {
        this.compatibleProducts = this.request.query.projectId
          ? await this.getCompatibleProducts()
          : null;
        if (
          this.compatibleProducts.length === 1 &&
          this.compatibleProducts.models[0].code === conf.settings.DUPLICATE_MATTED_PRINTS_DATA.code
        ) {
          let url = `/duplicate-project?projectId=${this.projectId}&destinationProductType=${this.compatibleProducts.models[0].productType}&duplicateCode=${this.compatibleProducts.models[0].duplicateCode}&language=${this.request.language}`;
          navigateTo(this.request, this.response, url, {}, false);
        }
      } catch (err) {
        Logger.error('DuplicateProductsController:getCompatibleProducts', {
          error: err
        });
      }
      this.stopInitialRendering();
    }
  }

  async getCompatibleProducts() {
    return await this.compatibleProductsCollection.fetch();
  }
}

export class DuplicateController extends controllers.BaseController {
  static get loginRequired() {
    return false;
  }

  get view() {
    return DuplicateView;
  }

  get context() {
    return Object.assign(super.context, {
      duplicateProject: this.duplicateProject
    });
  }

  async init() {
    if (runtime.isClient) {
      this.duplicateProject = true;
      this.isError = false;
      this.httpRequestConfig = {};
      this.httpRequestSetUp();

      let duplicatedProject = null;

      try {
        this.destinationProductType = this.request.query.destinationProductType;
        let projectId = this.request.query.projectId;
        let duplicateCode = this.request.query.duplicateCode;
        const legacy = false;

        duplicatedProject = await this.createDuplicatedProject(
          `${conf.settings.PROFESSIONAL_PROJECTS_URL}${projectId}/duplicate/?softwareCode=${conf.settings.PROFESSIONAL_SOFTWARE_CODE}`,
          {
            projectId: projectId,
            destinationProductType: this.destinationProductType,
            duplicateCode: duplicateCode,
            legacy: legacy
          }
        );
      } catch (err) {
        Logger.error('DuplicateProject.create', {
          error: err
        });
        GuiErrors.modalInfo(
          this.i18n.gettext('Something is wrong. We invite you to try again.'),
          null,
          this.i18n.gettext('Ok'),
          null,
          this.redirectToShoppingCart.bind(this)
        );
      }

      if (duplicatedProject) {
        this.duplicatedProjectId = duplicatedProject.id;

        try {
          let duplicatedProjectModel = new DuplicateProjectsCollection({
            projectId: this.duplicatedProjectId
          });
          await this.pollingRequests(duplicatedProjectModel);
        } catch (err) {
          Logger.error('DuplicateProject.getDuplicatedProject', {
            error: err
          });
          GuiErrors.modalInfo(
            this.i18n.gettext('Something is wrong. We invite you to try again.'),
            null,
            this.i18n.gettext('Ok'),
            null,
            this.redirectToShoppingCart.bind(this)
          );
        }
      }
      this.render();
    } else {
      this.render();
    }
  }

  httpRequestSetUp() {
    this.httpRequestConfig = {
      headers: {
        Authorization: OAuth2.getBearerToken(),
        'x-api-key': conf.settings.X_API_KEY,
        'Accept-Language': this.request.language
      }
    };
    this.httpRequest = axios;
  }

  async createDuplicatedProject(url, data) {
    return this.httpRequest.post(url, data, this.httpRequestConfig).then((response) => {
      return response.data;
    });
  }

  async pollingRequests(duplicatedProjectModel) {
    let options = {
      delay: 5000
    };

    let self = this;
    let destinationProductType = this.destinationProductType;

    let pollerProcess = poller.get(duplicatedProjectModel, options);
    pollerProcess.on('success', function (model) {
      model.models.map((item) => {
        if (
          item.get('creationStatus').toUpperCase() === 'COMPLETED' ||
          item.get('creationStatus').toUpperCase() === 'INPROGRESS'
        ) {
          pollerProcess.stop();
          pollerProcess.destroy();
          self.redirectToConfigurator(item.id, destinationProductType);
        } else if (item.get('creationStatus').toUpperCase() === 'ERROR') {
          pollerProcess.stop();
          pollerProcess.destroy();
          GuiErrors.modalInfo(
            self.i18n.gettext('Something is wrong. We invite you to try again.'),
            null,
            self.i18n.gettext('Ok'),
            null,
            self.redirectToShoppingCart.bind(self)
          );
        }
      });
    });
    pollerProcess.on('error', function () {
      GuiErrors.modalInfo(
        self.i18n.gettext('Something is wrong. We invite you to try again.'),
        null,
        self.i18n.gettext('Ok'),
        null,
        self.redirectToShoppingCart.bind(self)
      );
      console.error('polling is failed');
    });
    pollerProcess.start();
  }

  redirectToConfigurator(projectId, productType) {
    let url = `album/edit/${projectId}`;

    switch (productType) {
      case 'EventBook':
        url = `eventbook/edit/${projectId}`;
        break;
      case 'Album':
        url = `album/edit/${projectId}`;
        break;
      case 'MattedPrints':
        url = `matted-prints/edit/${projectId}`;
        break;
      default:
        Logger.error(`DuplicateProject.redirectToConfigurator - ${productType} not exists`);
    }

    navigateTo(this.request, this.response, url, {}, true);
  }

  redirectToShoppingCart() {
    let url = conf.settings.CART_BASE_URL;
    navigateTo(this.request, this.response, url, {}, true);
  }
}
