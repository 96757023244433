import { routers } from 'outlinejs';
import { routing } from 'outlinejs';

import { EventBookStylesController, AlbumStylesController } from './controllers';

export default class extends routers.BaseRouter {
  static get urlPatterns() {
    return {
      'event-book': routing.url('design:EventBook', EventBookStylesController),
      album: routing.url('design:Album', AlbumStylesController)
    };
  }
}
