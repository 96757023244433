import React from 'react';

import { views } from 'outlinejs';

export class UnauthorizedView extends views.BaseView {
  render() {
    return (
      <div className="container">
        <div className="row">
          <div className="col-xs-12">
            <h1 className="unauthorized-main-title">
              {this.i18n.gettext('Non sei autorizzato a visualizzare questa pagina')}
            </h1>
          </div>
        </div>
      </div>
    );
  }
}
