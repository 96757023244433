import React from 'react';
import PropTypes from 'prop-types';
import { AeMarkupText } from '@photosi/albumepoca-ui';
import { components } from 'outlinejs';

import { SelectButton } from './selectButton';

import { ColorCorrectionForm } from './colorCorrectionForm';
import FSDBigImg from '../media/images/fsd_big.jpg';
import FSDImg from '../media/images/fsd.jpg';

export class FsdServiceSection extends components.BaseComponent {
  constructor(props) {
    super(props);

    this.onSelect = this.onSelect.bind(this);
    this.onShowMore = this.onShowMore.bind(this);
  }

  get showMoreTitle() {
    return this.i18n.gettext('Design by us');
  }

  get showMoreContent() {
    return (
      <div className="impagination__modal">
        <div className="row">
          <div className="col-sm-12">
            <div className="impagination__modal__text">
              {this.i18n.gettext('Inviaci le tue foto e lo impaginiamo noi')}
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12 text-center">
            <div className="impagination__modal__picture">
              <img src={FSDBigImg} />
              <div className="timeline__content">
                <div className="timeline__title">
                  <div className="timeline__item timeline__item--you">
                    {this.i18n.gettext('Tu')}
                  </div>
                  <div className="timeline__item timeline__item--we">
                    {this.i18n.gettext('Noi')}
                  </div>
                </div>
                <div className="timeline__pins">
                  <div className="timeline__item timeline__item--you">
                    <div className="timeline__action">
                      {this.i18n.gettext('Choose the layout style')}
                    </div>
                  </div>
                  <div className="timeline__item timeline__item--you">
                    <div className="timeline__action">
                      {this.i18n.gettext('Upload high resolution images')}
                    </div>
                  </div>
                  <div className="timeline__item timeline__item--we">
                    <div className="timeline__action">
                      {this.i18n.gettext('We are make layout for you')}
                    </div>
                  </div>
                  <div className="timeline__item timeline__item--you">
                    <div className="timeline__action">
                      {this.i18n.gettext('Get the layout. Control it and modify it autonomously')}
                    </div>
                  </div>
                  <div className="timeline__item timeline__item--you">
                    <div className="timeline__action">
                      {this.i18n.gettext('You can share the layout with your customer')}
                    </div>
                  </div>
                  <div className="timeline__item timeline__item--you">
                    <div className="timeline__action">
                      {this.i18n.gettext('Purchase the product')}
                    </div>
                  </div>
                  <div className="timeline__item timeline__item--we">
                    <div className="timeline__action">
                      {this.i18n.gettext('Printing and delivery of the product')}
                    </div>
                  </div>
                </div>
                <div className="timeline__arrow" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  onSelect() {
    const { selectService, code, disabled } = this.props;
    if (disabled) {
      return false;
    }
    selectService(code);
  }

  onShowMore() {
    const { showMore, disabled } = this.props;
    if (disabled) {
      return false;
    }
    showMore(this.showMoreContent, this.showMoreTitle);
  }

  render() {
    const {
      code,
      price,
      selected,
      disabled,
      serviceColorCorrections,
      colorCorrectionCode,
      handleColorCorrectionChange
    } = this.props;

    const helpersClass = selected ? 'switchers__item active' : 'switchers__item';
    const blockClass = disabled ? 'service__blocked' : '';

    return (
      <div className="col-md-4">
        <div className={`${helpersClass} ${blockClass}`}>
          <div className="switchers__image">
            <img src={FSDImg} alt={'FullServiceDesign'} />
          </div>
          <div className="switchers__text">
            <div className="fix_min_height text-left">
              <p>
                <strong className={'text-uppercase'}>
                  <AeMarkupText
                    text={this.i18n.gettext('Inviaci le tue foto e  \r le impaginiamo noi.')}
                  />
                </strong>
                <AeMarkupText
                  text={this.i18n.gettext(
                    'Scegli uno stile e invii le immagini in alta  \r risoluzione'
                  )}
                />
              </p>
              <div className="switchers__price">
                {price && <span className="pull-right">+ {price}</span>}
              </div>
            </div>
            <div className="color_correction">
              <div className="divider" />
              {serviceColorCorrections && serviceColorCorrections.length && (
                <ColorCorrectionForm
                  serviceColorCorrections={serviceColorCorrections}
                  colorCorrectionCode={colorCorrectionCode}
                  inputId={'fullServiceDesignColorCorrection'}
                  handleColorCorrectionChange={handleColorCorrectionChange}
                />
              )}
            </div>
          </div>
          <div className="switchers__actions">
            <form action="#">
              <SelectButton
                isSelected={selected}
                handleSelect={this.onSelect}
                btnText={this.i18n.gettext('Scegli')}
                action={`set_service_${code}`}
              />
            </form>
          </div>
        </div>
        <a onClick={this.onShowMore}>{this.i18n.gettext('Approfondisci')}</a>
        {disabled && (
          <div className="alert alert-info">
            {this.i18n.gettext('Il servizio non è disponibile per il formato scelto')}
          </div>
        )}
      </div>
    );
  }
}

FsdServiceSection.propTypes = {
  language: PropTypes.string,
  code: PropTypes.string,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  selectService: PropTypes.func,
  showMore: PropTypes.func,
  colorCorrectionCode: PropTypes.string,
  handleColorCorrectionChange: PropTypes.func,
  serviceColorCorrections: PropTypes.array
};
