import React from 'react';

import { views } from 'outlinejs';
import { AeLoader } from '@photosi/albumepoca-ui';
import { LayoutView } from '../core/views';

export class SetShopCodeView extends LayoutView {
  constructor(props) {
    super(props);

    this.state = { shopCode: '' };

    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(event) {
    this.setState({ shopCode: event.target.value });
  }

  handleSubmit(event) {
    const { shopCode } = this.state;

    let fullShopCode = String(shopCode);
    if (shopCode && shopCode.length < 11) {
      let fillerLength = 7 - shopCode.length;
      fullShopCode = '0001' + new Array(fillerLength + 1).join('0') + String(shopCode);
    }

    this.props.delegate.onSubmit(fullShopCode);

    event.preventDefault();
  }

  render() {
    const { initViewRendering, error } = this.props;
    const { shopCode } = this.state;

    return (
      <div className="container">
        <AeLoader active={initViewRendering} />
        <div className="row">
          <div className="col-md-4 col-sm-6 col-xs-12">
            <form onSubmit={this.handleSubmit}>
              <div className="form-group" style={{ marginTop: 20 }}>
                <label htmlFor="exampleInputEmail1">{this.i18n.gettext('Shop code label')}</label>
                <span id="helpBlock" className="help-block">
                  {this.i18n.gettext('Insert shop code to impersonate user')}
                </span>
                <input
                  type="number"
                  className="form-control"
                  value={shopCode}
                  onChange={this.handleChange}
                />
              </div>
              {error && (
                <div className="alert alert-danger" role="alert">
                  {error}
                </div>
              )}
              <button type="submit" className="btn btn-primary">
                {this.i18n.gettext('Submit')}
              </button>
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export class RemoveShopCodeView extends views.BaseView {
  constructor(props) {
    super(props);
  }

  render() {
    const { initViewRendering } = this.props;

    return (
      <div className="container">
        <AeLoader active={initViewRendering} />
      </div>
    );
  }
}
