import { routers } from 'outlinejs';
import { routing } from 'outlinejs';

import {
  EventBookLayoutDispatchController,
  AlbumLayoutDispatchController,
  MattedPrintsLayoutDispatchController
} from './controllers';

export default class extends routers.BaseRouter {
  static get urlPatterns() {
    return {
      'event-book-dispatch': routing.url(
        'layoutDispatch:EventBook',
        EventBookLayoutDispatchController
      ),
      'album-dispatch': routing.url('layoutDispatch:Album', AlbumLayoutDispatchController),
      'matted-prints-dispatch': routing.url(
        'layoutDispatch:MattedPrints',
        MattedPrintsLayoutDispatchController
      )
    };
  }
}
