import { routers } from 'outlinejs';
import { routing } from 'outlinejs';

import { DuplicateController, DuplicateProductsController } from './controllers';

export default class extends routers.BaseRouter {
  static get urlPatterns() {
    return {
      '': routing.url('duplicate-project:main', DuplicateController),
      duplicate: routing.url('duplicate-project:productsList', DuplicateProductsController)
    };
  }
}
