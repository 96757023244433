import { BaseModel } from '../core/models';
import { conf } from 'outlinejs';

export class DefaultBlockProject extends BaseModel {
  get urlRoot() {
    return conf.settings.EDITOR_VELOCE_DEFAULT_BLOCK_PROJECTS_URL;
  }

  get id() {
    return this.get('id');
  }

  get projectName() {
    return this.get('projectName');
  }

  get productConfigurationId() {
    return this.get('productConfigurationId');
  }

  get productConfigurationType() {
    return this.get('productConfigurationType');
  }

  get shopCode() {
    return this.get('shopCode');
  }

  get language() {
    return this.get('language');
  }

  get applicationUrl() {
    if (this.productConfigurationType === 'Album') {
      return `${conf.settings.EDITOR_VELOCE_ALBUM_URL}?projectId=${this.id}`;
    } else {
      return `${conf.settings.EDITOR_VELOCE_EVENTBOOK_URL}?projectId=${this.id}`;
    }
  }
}

export class MattedPrintsProject extends BaseModel {
  get urlRoot() {
    return conf.settings.EDITOR_VELOCE_MATTED_PRINTS_PROJECTS_URL;
  }

  get id() {
    return this.get('id');
  }

  get projectName() {
    return this.get('projectName');
  }

  get productConfigurationId() {
    return this.get('productConfigurationId');
  }

  get shopCode() {
    return this.get('shopCode');
  }

  get language() {
    return this.get('language');
  }

  get applicationUrl() {
    return `${conf.settings.EDITOR_VELOCE_MATTED_PRINTS_URL}?projectId=${this.id}`;
  }
}

export class ProjectServices extends BaseModel {
  get id() {
    return this.get('code');
  }

  get code() {
    return this.get('code');
  }

  get formattedPrice() {
    return this.get('formattedPrice');
  }

  get colorCorrections() {
    return this.get('colorCorrections');
  }
}
